import { useState } from "react";
import { AppContext } from "./context";
import { axiosInstance } from "../helper";


const AppProvider = ({ children }) => {

    const [profile, setProfile] = useState({});

    // GET Profile
    const getprofile = async () => {
        try {
            const res = await axiosInstance.get("/auth/profile");

            console.log("profile res in context : ", res);

            if(res.data.status === "success"){
                setProfile(res.data.data)
            }

        } catch (error) {
            console.error(error)
        }
    }

    


    return (
        <AppContext.Provider value={{ getprofile, profile, setProfile }}>
            {children}
        </AppContext.Provider>
    )
}


export default AppProvider;