import React from 'react'

export default function Employeedetails() {
  return (
    <>

<div class="content">
      <div class="container-fluid py-2">
         {/* <div class="card-header d-sm-flex align-items-center  justify-content-between">
            <h4 class="card-title flex-fill">HRMS</h4>
            <a class="btn btn-sm btn-primary" href="/admin/attendance/dailyreport">Daily Report</a>
         </div> */}
         <div class="row">
            <div class="col-lg-12">
               <div class="card custom-card mb-3">
                  <div class="card-header justify-content-between">
                     <div class="card-title d-flex gap-3">
                        <span>Employee Detail</span>
                        <div class="d-flex gap-2"></div>
                     </div>
                     <div class="flex flex-wrap gap-2"><span class="badge badge-md   bg-primary-transparent">Today : 9 Aug 2024</span></div>
                  </div>

                  <div class="card-header pb-0 border-0 flex-wrap align-items-start">
								<div class="col-md-8">
									<div class="user d-sm-flex d-block pe-md-5 pe-0">
										<img src="https://jobick.dexignlab.com/xhtml/images/profile/pic1.jpg" alt=""/>
										<div class="ms-sm-3 ms-0 me-md-5 md-0">
											<h5 class="mb-1 font-20"><a href="/" class="text-dark">Andrew Jonson</a></h5>
											<div class="listline-wrapper mb-2">
												<span class="item"><i class="text-primary bi bi-envelope"></i>example@gmail.com</span>
												<span class="item"><i class="text-primary bi bi-person-badge"></i>Manager</span>
												<span class="item"><i class="text-primary bi bi-geo-alt"></i>Indonesia</span>
											</div>
										</div>
									</div>
								</div>
								
							</div>

                  <div class="card-body pb-0 pt-1">
										
										<hr/>

                                        <div class="row">
									<div class="col-xl-6 col-md-6">
										<ul class="list-style-1">
											<li><label class="custom-label-2 mb-0">Employee ID :</label>MNB02</li>
											
											<li><label class="custom-label-2 mb-0">Phone :</label>1234598765</li>
											<li><label class="custom-label-2 mb-0">Designation :</label>PHP Programmer</li>
                                            <li><label class="custom-label-2 mb-0">Department :</label>React Development</li>
                                            <li><label class="custom-label-2 mb-0">Date of Joining :</label>13 June 2021</li>
											
                                            <li><label class="custom-label-2 mb-0">Monthly Salary :</label>₹70000.00</li>
                                            <li><label class="custom-label-2 mb-0">Annual Salary :</label>₹70Lacs</li>

											<li><label class="custom-label-2 mb-0">Experience :</label>3 Yrs</li>
                                            <li><label class="custom-label-2 mb-0">Total Experience :</label>3 Yrs</li>
                                            <li><label class="custom-label-2 mb-0">Qualification :</label>B.Tech(CSE)</li>
											
										</ul>
									</div>
									<div class="col-xl-6 col-md-6">
										<ul class="list-style-1">
                                        
											<li><label class="custom-label-2 mb-0">Key Skills :</label>Good Communication, Planning and research skills</li>
										
											<li><label class="custom-label-2 mb-0">Launguages :</label>English, German, Spanish</li>
											
											<li><label class="custom-label-2 mb-0">Industry :</label>it Software/ Developer</li>
											<li><label class="custom-label-2 mb-0">Date Of Birth :</label>13 June 1996</li>
											<li><label class="custom-label-2 mb-0">Gender :</label>Female</li>
											<li><label class="custom-label-2 mb-0">Marital Status :</label>Unmarried</li>
                                            <li><label class="custom-label-2 mb-0">Father's Name :</label>Mohan Lal</li>
                                            <li><label class="custom-label-2 mb-0">Alternate Number :</label>9898989874</li>
                                            
											<li><label class="custom-label-2 mb-0">Permanent Address :</label>USA</li>
											<li><label class="custom-label-2 mb-0">Zip code :</label>12345</li>
										</ul>
									</div>
								</div>



                                <div class="table-responsive">
   <table class="table text-nowrap table-hover border table-bordered">
      <thead>
         <tr>
           
            <th class="text-center">Aadhar</th>
            <th class="text-center">Pancard</th>
            <th class="text-center">Profile</th>
            <th class="text-center">Qual Documant</th>
         </tr>
      </thead>
      <tbody>
         <tr>
            <td class="docimg text-center" data-bs-toggle="modal" data-bs-target="#empdocpop"><img src="/files/nonedoc.png" alt=""/> <span class="badge bg-primary-transparent"> <i class="bi bi-ban"></i> Pending</span> </td>
            <td class="docimg text-center" data-bs-toggle="modal" data-bs-target="#empdocpop"><img src="/files/pendingdoc.png" alt=""/> <span class="badge bg-info-transparent"> <i class="bi bi-eye"></i> uploaded</span></td>
            <td class="docimg text-center" data-bs-toggle="modal" data-bs-target="#empdocpop"><img src="/files/approvedoc.png" alt=""/> <span class="badge bg-success-transparent"> <i class="bi bi-eye"></i> Approved</span></td>
            <td class="docimg text-center" data-bs-toggle="modal" data-bs-target="#empdocpop"><img src="/files/rejectdoc.png" alt=""/> <span class="badge bg-danger-transparent"><i class="bi bi-eye"></i> rejected</span></td>
         </tr>
         
      </tbody>
   </table>
</div>

                                
									
										
										<div class="d-flex justify-content-between py-2 border-bottom flex-wrap">
											<span>Job ID: #8976542</span>
											<span>Posted By <strong>Company</strong>/ 12-01-2023</span>
										</div>


									</div>
                                    <div class="card-footer d-flex flex-wrap justify-content-between align-items-center">
								<div class="mb-md-2 mb-3 exp-del">
									<span><i class="bi bi-circle-fill me-1 op4"></i>3 Yrs Of Working Experience in   <strong>Abcd Pvt Ltd</strong></span>
								</div>
								<div>
									<a href="/" class="btn btn-primary btn-sm me-2 mb-1"><i class="bi bi-download me-1"></i>Download</a>
									<a href="/" class="btn btn-warning btn-sm me-2 mb-1"><i class="bi bi-share-alt me-1"></i>Share Profile</a>
									<a href="/" class="btn btn-danger btn-sm me-2 mb-1"><i class="bi bi-ban me-1"></i>Block </a>
								</div>
							</div>
                 
                 
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="modal blurmodal fade" id="empdocpop" tabindex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel"  aria-modal="true" role="dialog">
   <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content" >
         <div class="modal-header bg-primary">
            <h1 class="modal-title text-white fs-5" id="exampleModalLabel">Documant Name - </h1>
            <button type="button" class="btn-close bg-danger" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body">
            <div class="card-body py-0 px-0 input">
               <form>
                  <div class="row">
                  <div class=" col-12 mb-2"><label class="form-label">Document No</label><input name="name" placeholder="enter Remarks" type="text" class="form-control" id="name" required="" value="8798769654544"/></div>
                  <div class=" col-12 mb-2">
                    <img src='https://www.medianama.com/wp-content/uploads/2023/03/aadhaar-card-7579588_1280.png' className='img-fluid' alt=''/>
                  </div>
                   
                   </div>
                  <div class="row mt-1">
                     <div class="col-12">
                        <button type="submit" class="btn btn-sm btn-success font-13 " data-bs-dismiss="modal" aria-label="Close">
                           <div class="d-flex gap-2"><i class="bi bi-save"></i><span> Approve</span></div>
                        </button>
                        <button class="btn btn-sm btn-danger ms-2 font-13" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-backspace"></i> <span>Reject</span></button>
                        
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>

      
    </>
  )
}
