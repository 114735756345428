import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function TermsofService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

<Helmet>
      <title>Terms of Service | Taskflier </title>
      <meta name="description" content="discription" />
      </Helmet>
    
      {" "}
      <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
      <link rel="stylesheet" type="text/css" href="/main/css/responsive.css"></link>

      <div className="main-page-wrapper font-rubik">
        <Header />
        
        <div class="fancy-hero-five">
				<div class="bg-wrapper">
					<div class="container">
						<div class="text-center row">
							<div class="col-xl-9 m-auto">
								<h1 class="heading">Terms Of Service</h1>
								<p class="sub-heading space-xs font-gordita mt-15">We are a creative company that focuses on establishing long-term relationships with customers.</p>
							</div>
						</div>
					</div>
				</div>
		</div>


		<div class="fancy-feature-sixteen pt-80 md-mt-100 bgwhite  " id="feature">
   <div class="container">
      <div class="title-style-twelve">
         <div class="row align-items-center">
            <div class="col-lg-12 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
               <div class="text-wrapper">


Terms of services 
<h3 class="maintitle3">1. Acceptance of Terms </h3>
<p className="mainp3">By using Taskflier, you agree to follow these terms. If you don’t agree, please don’t use our service.</p>

<h3 class="maintitle3">2. Using Taskflier</h3>
<p className="mainp3">Account Creation: You need to create an account to use Taskflier. Keep your login information safe and don’t share it with others.
Your Responsibilities: Use Taskflier legally and responsibly. Don’t use it to do anything illegal or harmful.</p>
<h3 class="maintitle3">3. Your Content</h3>
<p className="mainp3"><b>Ownership</b>: You own the content you create and upload to Taskflier.</p>
<p className="mainp3"><b>Permissions</b>: By using Taskflier, you give us permission to use, store, and share your content as needed to provide our service.</p>
<p className="mainp3"><b>Sharing</b>: Be mindful of what you share and who you share it with. You’re responsible for your content and how you share it.</p>

<h3 class="maintitle3">4. Privacy and Security</h3>
<p className="mainp3"><b>Data Protection</b>: We’re committed to protecting your privacy and data. Read our privacy policy to understand how we handle your information.</p>
<p className="mainp3"><b>Security Measures</b>: We use various security measures to protect your data, but we can’t guarantee absolute security.</p>

<h3 class="maintitle3">5. Payments and Subscriptions</h3>
<p className="mainp3"><b>Free and Paid Plans</b>: Taskflier offers both free and paid plans. Paid plans have additional features and benefits.
</p>
<p className="mainp3"><b>Billing</b>: If you choose a paid plan, you agree to pay the fees. We’ll bill you according to the plan you select.</p>

<h3 class="maintitle3">6. Changes to the Service</h3>
<p className="mainp3">We may update or change Taskflier from time to time. We’ll try to notify you about major changes, but some updates may happen automatically.</p>

<h3 class="maintitle3">7. Termination</h3>
<p className="mainp3"><b>By You</b>: You can stop using Taskflier at any time. If you want to delete your account, you can do so through your account settings.
</p>
<p className="mainp3"><b>By Us</b>: We may suspend or terminate your access if you violate these terms or misuse the service.</p>

<h3 class="maintitle3">8. Limitation of Liability</h3>
<p className="mainp3">We strive to keep Taskflier running smoothly, but we can’t guarantee it will always be perfect. We’re not responsible for any damages or losses that result from using Taskflier.
</p>
<h3 class="maintitle3">9. Governing Law</h3>
<p className="mainp3">These terms are governed by the laws of the country where Taskflier is based. Any disputes will be resolved in local courts.
</p>

<h3 class="maintitle3">10. Contact Us</h3>
<p className="mainp3">If you have questions or need help, please contact us through our support page or email.</p>



 
				</div>
               
            </div>
           
			
         </div>
      </div>
     
    

</div>
</div>

        <div className="footer-bg-wrapper bg-white">
          <div className="bubble-one" />
          <div className="bubble-two" />
          <div className="bubble-three" />
          <div className="fancy-short-banner-two">
            <div className="container">
              <div className="content-wrapper">
                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                  <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                  <Link
                    to="https://mnbproject.com/#"
                    data-toggle="modal"
                    data-target="#contactModal"
                  >
                    Request Demo
                  </Link>
                  <div className="bubble-one" />
                  <div className="bubble-two" />
                  <div className="bubble-three" />
                  <div className="bubble-four" />
                  <div className="bubble-five" />
                </div>
             
              </div>
             
            </div>
           
          </div>
       
          <Footer />
        
        </div>



       



        <button className="scroll-top" style={{ display: "none" }}>
          <i className="fa fa-angle-up" aria-hidden="true" />
        
        </button>




      </div>
    </>
  );
}

export default TermsofService;