import React from 'react';
const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const Projects = React.lazy(() => import('./Project/Projects'));
const Project = React.lazy(() => import('./Project/Project'));
const EditProject = React.lazy(() => import('./Project/edit-project'));
const Tickets = React.lazy(() => import('./Tickets/tickets'));
const NewTicket = React.lazy(() => import('./Tickets/new-ticket'));
const TikectDetails = React.lazy(() => import('./Tickets/TikectDetails'));
const Appointments = React.lazy(() => import('./appointment/appointments'));
const CreateAppointment = React.lazy(() => import('./appointment/create-appointment'));
const ChangePassword = React.lazy(() => import('./../Components/ChangePassword'));
const Tasks = React.lazy(() => import('./Project/tasks'));
const Profile = React.lazy(() => import('./Profile/Profile.jsx'));
const ManageProfile = React.lazy(() => import('./Profile/ManageProfile.jsx'));
const Error404 = React.lazy(() => import('./../Components/Error404'));
const Messages = React.lazy(() => import('./chat/messages'));
const LogDetails = React.lazy(() => import('./Project/logDetails'));
const ToDos = React.lazy(() => import('./ToDo/ToDos'));


const UserRoutes = [
    {
        path: 'user',
        children: [
            {
                path: 'dashboard',
                element: <Dashboard/>
            },
            {
                path: 'projects',
                element: <Projects/>
            },
            {
                path: 'project/:projectID',
                element: <Project/>
            },
            {
                path: 'project/:projectID/edit',
                element: <EditProject/>
            },
            {
                path: 'tasks',
                element: <Tasks/>
            },
            {
                path: 'tickets',
                element: <Tickets/>
            },
            {
                path: 'new-ticket',
                element: <NewTicket/>
            },
            {
                path:'log/:projectID/:logID',
                element:<LogDetails/>
            },
            {
                path: 'project/:projectID/ticket/:ticketID',
                element: <TikectDetails/>
            },
            {
                path: 'appointments',
                element: <Appointments/>
            },
            {
                path: 'create/appointment',
                element: <CreateAppointment/>
            },
            {
                path: 'profile',
                element: <Profile/>
            },
            {
                path: 'profile/edit',
                element: <ManageProfile/>
            },
            {
                path: 'password',
                element: <ChangePassword/>
            },
            {
                path:'messages',
                element: <Messages/>
            },
            {
                path: '*',
                element: <Error404/>
            },
            {
                path:'to-dos',
                element: <ToDos/>
            },
        ]
    }
]
export default UserRoutes;