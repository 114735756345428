import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Security() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
      <title>Security | Taskflier </title>
      <meta name="description" content="discription" />
      </Helmet>
      {" "}
      <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
      <link rel="stylesheet" type="text/css" href="/main/css/responsive.css"></link>

      <div className="main-page-wrapper font-rubik">
        <Header />

        <div class="fancy-hero-five">
				<div class="bg-wrapper">
					<div class="container">
						<div class="text-center row">
							<div class="col-xl-9 m-auto">
								<h1 class="heading">Security</h1>
								<p class="sub-heading space-xs font-gordita mt-15">At Taskflier, we take security seriously. Here’s how we keep your information safe</p>
							</div>
						</div>
					</div>
				</div>
		</div>


		<div class="fancy-feature-sixteen pt-80 md-mt-100 bgwhite  " id="feature">
   <div class="container">
      <div class="title-style-twelve">
         <div class="row align-items-center">
            <div class="col-lg-12 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
               <div class="text-wrapper">
                
			 
			  <h3 class="maintitle3 mt-0">1. Strong Encryption</h3>
			  <p className="mainp3">We use strong encryption to protect your data. This means that your information is turned into a code that can only be read by you and those you share it with. It’s like locking your data in a safe that only you have the key to.
</p>
<h3 class="maintitle3 mt-0">2. Secure Connections</h3>
<p className="mainp3">Whenever you use Taskflier, whether on your computer or phone, the data travels through a secure connection. This ensures that no one can eavesdrop on your information as it moves from your device to our servers.</p>

<h3 class="maintitle3">3. Regular Security Updates</h3>
<p className="mainp3">We regularly update Taskflier with the latest security features. This helps protect against new threats and keeps your information secure.</p>

<h3 class="maintitle3">4. Access Controls</h3>
<p className="mainp3">Only you and the people you invite can access your tasks and projects. You control who sees what, and you can change these settings anytime.</p>

<h3 class="maintitle3">5. Safe Data Storage</h3>
<p className="mainp3">Your data is stored in secure data centers that have strict access controls. Only authorized personnel can access these centers, and they follow strict security protocols.</p>

<h3 class="maintitle3">6. Monitoring and Alerts</h3>
<p className="mainp3">We constantly monitor Taskflier for unusual activity. If something suspicious happens, we act quickly to protect your account and notify you.</p>

<h3 class="maintitle3">7. Data Backups</h3>
<p className="mainp3">We regularly back up your data. This means that even if something goes wrong, like a system crash, your information is safe and can be restored.</p>

<h3 class="maintitle3">8. User Authentication</h3>
<p className="mainp3">We use secure methods to verify your identity, like strong passwords and two-factor authentication (2FA). 2FA adds an extra layer of security by requiring a second form of verification, like a code sent to your phone, in addition to your password.</p>

<h3 class="maintitle3">Your Security is Our Priority</h3>

<p className="mainp3">We know how important it is to keep your information safe. That’s why we invest in the best security measures and constantly work to improve them. With Taskflier, you can focus on your tasks knowing your data is protected.
</p>			  
				</div>
               
            </div>
           
			
         </div>
      </div>
     
    

</div>
</div>

        <div className="footer-bg-wrapper bg-white">
          <div className="bubble-one" />
          <div className="bubble-two" />
          <div className="bubble-three" />
          <div className="fancy-short-banner-two">
            <div className="container">
              <div className="content-wrapper">
                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                  <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                  <Link
                    to="https://mnbproject.com/#"
                    data-toggle="modal"
                    data-target="#contactModal"
                  >
                    Request Demo
                  </Link>
                  <div className="bubble-one" />
                  <div className="bubble-two" />
                  <div className="bubble-three" />
                  <div className="bubble-four" />
                  <div className="bubble-five" />
                </div>
             
              </div>
             
            </div>
           
          </div>
       
          <Footer />
        
        </div>



       



        <button className="scroll-top" style={{ display: "none" }}>
          <i className="fa fa-angle-up" aria-hidden="true" />
        
        </button>




      </div>
    </>
  );
}

export default Security;