import React, { useEffect, useState } from 'react';
import { axiosInstance } from "../../../helper";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function PaymentSuccess() {
    const { id } = useParams();
    const [data, setdata] = useState();
    useEffect(() => {
        axiosInstance.get(`https://phplaravel-979432-4660383.cloudwaysapps.com/api/taskflier/payment/${id}`).then((res) => {
            if (res.status === 200 && res.data.status === 1) { setdata(res.data.data) }
        }).catch(() => { })
    }, []);
    console.log(data);
    return (
        <>
         <Helmet>
      <title>Payment Success | Taskflier </title>
      <meta name="description" content="discription" />
      </Helmet>

            <>
                <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
                <link rel="stylesheet" type="text/css" href="/main/css/responsive.css"></link>
            </>
            <div className="main-page-wrapper font-rubik">

                <div className="theme-main-menu sticky-menu theme-menu-one">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="logo">
                            <a href="/">
                                <img src="/main/images/logo/mnb-project.png" alt="" />
                            </a>
                        </div>
                        <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
                            <div className="nav-container">
                                <button className="navbar-toggler">
                                    <span />
                                </button>
                                <div className="navbar-collapse collapse" id="navbarSupportedContent">
                                    <div className="d-lg-flex align-items-center">
                                        <ul className="navbar-nav">
                                            <li className="nav-item dropdown position-static active">
                                                <a className="nav-link" href="/">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/#solutions">
                                                    Solutions
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/#feature">
                                                    Features
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    href="/#resources"
                                                >
                                                    Resources
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/#">
                                                    Customers
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    href="/pricing"
                                                >
                                                    Pricing
                                                </a>
                                            </li>
                                        </ul>
                                        <ul className="right-button-group d-flex align-items-center justify-content-center">
                                            <li>
                                                <Link to="/login" className="signIn-action">
                                                    Login
                                                </Link>
                                            </li>
                                            <li>
                                                <a
                                                    // href="https://mnbproject.com/#"
                                                    data-toggle="modal"
                                                    data-target="#contactModal"
                                                    className="signUp-action"
                                                >
                                                    Request Demo
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>


                <div
                    id="resources"
                    class="fancy-feature-fortySix lg-container pt-40 lg-mt-100 pt-40 pb-150 md-pt-80 lg-pb-80"
                >
                    <div class="container position-relative">


                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-5 d-flex ">
                                <div class="block-style-five">
                                    <div class="title-style-seven text-center">
                                        <img src='./images/success.png' className='successpayment' alt="" />
                                        <h2 className='payment-head'>
                                            Payment <span>{data?.payment_status??''}</span>
                                        </h2>
                                        <p className='infor'>
                                            Dear User, Thanks for choosing TaskFlier, We are sending your ID Password to your mail ID after <b>30 minutes.</b>
                                        </p>

                                        <ul class="list-group mt-3 payment-list">
                                            <li class="list-group-item font-14 d-flex justify-content-between align-items-center">
                                                Transaction ID
                                                <span class="text-primary ">{data?.transaction_id??''}</span>
                                            </li>
                                            <li class="list-group-item font-14 d-flex justify-content-between align-items-center">
                                                Date & Time
                                                <span class="text-primary ">{data?.payment_date??''}</span>
                                            </li>
                                            <li class="list-group-item font-14 d-flex justify-content-between align-items-center">
                                                Plan
                                                <span class="text-primary ">{data?.package??''}</span>
                                            </li>
                                            <li class="list-group-item font-14 d-flex justify-content-between align-items-center">
                                                Amount
                                                <span class="text-primary ">{data?.amount??''}</span>
                                            </li>
                                        </ul>


                                        <Link class="btn btn-lg  btn-primary mt-4 w-100" to="/">Back To Home </Link>
                                    </div>

                                    {/* <h6 class="title"><span>Projects Management</span></h6>
         <p>Use MNB Project Management to create high-level plans and prioritize work to achieve your defined project goals.</p>
      */}

                                </div>
                            </div>

                        </div>





                    </div>
                    <img
                        src="/main/images/shape/246.svg"
                        alt=""
                        class="shapes shape-one"
                    />
                    <img
                        src="/main/images/shape/247.svg"
                        alt=""
                        class="shapes shape-two"
                    />
                    <img
                        src="/main/images/shape/248.svg"
                        alt=""
                        class="shapes shape-three"
                    />
                    <img
                        src="/main/images/shape/249.svg"
                        alt=""
                        class="shapes shape-four"
                    />
                </div>






                <div className="footer-bg-wrapper bg-white">
                    <div className="bubble-one" />
                    <div className="bubble-two" />
                    <div className="bubble-three" />
                    <div className="fancy-short-banner-two">
                        <div className="container">
                            <div className="content-wrapper">
                                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                                    <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                                    <a
                                        href="https://mnbproject.com/#"
                                        data-toggle="modal"
                                        data-target="#contactModal"
                                    >
                                        Request Demo
                                    </a>
                                    <div className="bubble-one" />
                                    <div className="bubble-two" />
                                    <div className="bubble-three" />
                                    <div className="bubble-four" />
                                    <div className="bubble-five" />
                                </div>{" "}
                                {/* /.bg-wrapper */}
                            </div>{" "}
                            {/* /.content-wrapper */}
                        </div>{" "}
                        {/* /.container */}
                    </div>{" "}
                    {/* /.fancy-short-banner-two */}
                    <footer class="theme-footer-one pt-130 md-pt-70">
                        <div class="top-footer">
                            <div class="container">
                                <div class="row justify-content-end">
                                    <div
                                        class="col-lg-3 col-md-12 footer-about-widget aos-init aos-animate"
                                        data-aos="fade-up"
                                        data-aos-duration="1200"
                                    >
                                        <a href="index.html" class="logo">
                                            <img src="/main/images/logo/mnb-project.png" alt="" />
                                        </a>
                                    </div>
                                    <div
                                        class="col-lg-3 col-md-4 footer-list aos-init aos-animate"
                                        data-aos="fade-up"
                                        data-aos-duration="1200"
                                        data-aos-delay="50"
                                    >
                                        <h5 class="footer-title">Features</h5>
                                        <ul>
                                            <li>
                                                <a href="#">Projects </a>
                                            </li>
                                            <li>
                                                <a href="#">Employee </a>
                                            </li>
                                            <li>
                                                <a href="#">Clients </a>
                                            </li>
                                            <li>
                                                <a href="#">Tickets </a>
                                            </li>
                                            <li>
                                                <a href="#">Appointments </a>
                                            </li>
                                            <li>
                                                <a href="#">Resource</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="col-lg-3 col-md-4 footer-list aos-init aos-animate"
                                        data-aos="fade-up"
                                        data-aos-duration="1200"
                                        data-aos-delay="100"
                                    >
                                        <h5 class="footer-title">Resources</h5>
                                        <ul>
                                            <li>
                                                <a href="#">Help Desk</a>
                                            </li>
                                            <li>
                                                <a href="#">Auto Message</a>
                                            </li>
                                            <li>
                                                <a href="#">Auto Message</a>
                                            </li>
                                            <li>
                                                <a href="#">Analytic Report</a>
                                            </li>
                                            <li>
                                                <a href="#">Add Leads</a>
                                            </li>
                                            <li>
                                                <a href="#">Data Security</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="col-lg-3 col-md-4 address-list aos-init aos-animate"
                                        data-aos="fade-up"
                                        data-aos-duration="1200"
                                        data-aos-delay="150"
                                    >
                                        <h5 class="footer-title">Address</h5>
                                        <ul>
                                            <li>
                                                <a href="#">support@taskflier.com</a>
                                            </li>
                                            <li>
                                                <Link to="#" class="mobile-num">
                                                    +91 99946 57491
                                                </Link>
                                            </li>
                                            <li>
                                                5th Block, 1217, Chord Rd, Near Shivanagar Signal, Rajajinagar, Bengaluru, Karnataka 560010
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <div class="bottom-footer-content">
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <ul
                                        class="order-sm-last aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-duration="1200"
                                        data-aos-delay="100"
                                        data-aos-anchor=".theme-footer-one"
                                    >
                                        <li>
                                            <a href="#">Privacy</a>
                                        </li>
                                        <li>
                                            <a href="#">Security</a>
                                        </li>
                                        <li>
                                            <a href="#">Terms</a>
                                        </li>
                                    </ul>
                                    <p
                                        class="order-sm-first aos-init aos-animate"
                                        data-aos="fade-right"
                                        data-aos-duration="1200"
                                        data-aos-delay="100"
                                        data-aos-anchor=".theme-footer-one"
                                    >
                                        Copyright @2024 MNB Project.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </footer>
                    {/* /.theme-footer-one */}
                </div>
                <div
                    className="modal fade modal-contact-popup-one"
                    id="contactModal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="contactModalTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <main className="main-body modal-content clearfix">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <img src="./HomePage_files/close.svg" alt="" />
                            </button>
                            <div className="right-side">
                                <p className="form-pre">Safe &amp; Confidential</p>
                                <h2 className="form-title">Request For a Free Demo</h2>
                                <form className="submitrequest">
                                    <div className="messages" />
                                    <div className="row controls">
                                        <div className="col-12">
                                            <div className="input-group-meta form-group mb-15">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    placeholder="Your Name"
                                                    name="Fname"
                                                    id="name"
                                                    required="required"
                                                    data-error="Name is required."
                                                />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group-meta form-group mb-15">
                                                <label>Email*</label>
                                                <input
                                                    type="email"
                                                    placeholder="Email Address"
                                                    name="email"
                                                    id="email"
                                                    required="required"
                                                    data-error="Valid email is required."
                                                />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group-meta form-group mb-15">
                                                <label>Phone*</label>
                                                <input
                                                    type="number"
                                                    placeholder="Phone No."
                                                    name="phone"
                                                    id="phone"
                                                    required="required"
                                                    data-error="Valid phone no. is required."
                                                />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group-meta form-group mb-35">
                                                <label>Apply For*</label>
                                                <select
                                                    name="Subject"
                                                    data-error="Valid Category is required."
                                                    id="subject"
                                                >
                                                    <option value="">-- Select Services --</option>
                                                    <option>IT Services</option>
                                                    <option>Charted Accountant</option>
                                                    <option>Commerce</option>
                                                    <option>Construction</option>
                                                    <option>Textiles</option>
                                                    <option>Electrical</option>
                                                </select>
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="theme-btn-seven w-100">
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </div>
                <button className="scroll-top" style={{ display: "none" }}>
                    <i className="fa fa-angle-up" aria-hidden="true" />
                </button>
                {/* 











 */}
            </div>
        </>

    );
}
