import React, { useEffect, useState } from 'react';
import { axiosInstance, dateOption, getProdDevURL } from "../../../helper";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";


export default function PricingPage() {

    const [Package, setPackage] = useState([]);
    

    useEffect(() => {
        window.scrollTo(0, 0);
        axiosInstance.get('https://phplaravel-979432-4660383.cloudwaysapps.com/api/taskflier/package').then((res) => {
            if (res.status === 200 && res.data.status === 1) { setPackage(res.data.data) }
        }).catch((err) => { })
    }, []);

    return (
        <>

<Helmet>
      <title>Affordable Project Management Software | TaskFlier Pricing </title>
      <meta name="description" content="Save time and money with TaskFlier's affordable Project management software. See our pricing options and choose the package that fits your needs. " />
      </Helmet>
         

            <>
                <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
                <link rel="stylesheet" type="text/css" href="/main/css/responsive.css"></link>
               
            </>
            <div className="main-page-wrapper font-rubik">
                <Header />



                <div class="pricing-section-one md-mb-80 bg-white">
                    <div class="fancy-hero-one">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class="font-rubik">Choose package match your budget</h2>
                                </div>
                                <div class="col-12 m-auto">
                                    <p class="font-rubik">Within  minutes installation · Try Team plan features for 10 days · No credit card
                                        required</p>
                                </div>
                            </div>
                            <ul class="nav nav-tabs justify-content-center pricing-nav-one">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#month">Monthly</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#year">Yearly</a>
                                </li>
                            </ul>
                        </div>
                        <div class="bubble-one"></div>
                        <div class="bubble-two"></div>
                        <div class="bubble-three"></div>
                        <div class="bubble-four"></div>
                        <div class="bubble-five"></div>
                        <div class="bubble-six"></div>
                    </div>

                    <div class="pricing-table-area">
                        <img src="images/shape/62.svg" alt="" class="shapes shape-one" />
                        <img src="images/shape/63.svg" alt="" class="shapes shape-two" />
                        <div class="container">
                            <div class="tab-content">
                                <div class="tab-pane active" id="month">
                                    <div class="row justify-content-center">
                                        {Package && Package.map((val) => {
                                            if(val.package_type==='monthly'){
                                                return (
                                                    <div class="col-lg-4 col-md-6">
                                                        <div class="pr-table-wrapper">
                                                            <div class="pack-name">{val.package ?? ''}</div>
                                                            <div class="price font-rubik">₹{val.amount ?? 0} </div>
                                                            <div class="pack-rec font-rubik">{val.limitation}</div>
                                                            <img src={val.icon} alt="" class="icon" />
                                                            <Link to={`/plan/${val.package}/${val.id}`} class="theme-btn-four">Choose Plan</Link>
                                                            <div class="trial-text font-rubik">{val.objective}</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                                <div class="tab-pane" id="year">
                                    <div class="row justify-content-center">
                                        {Package && Package.map((val) => {
                                           if(val.package_type==='yearly'){
                                            return (
                                                <div class="col-lg-4 col-md-6">
                                                    <div class="pr-table-wrapper">
                                                        <div class="pack-name">{val.package ?? ''}</div>
                                                        <div class="price font-rubik">₹{val.amount ?? 0} </div>
                                                        <div class="pack-rec font-rubik">{val.limitation}</div>
                                                        <img src={val.icon} alt="" class="icon" />
                                                        <Link to={`/plan/${val.package}/${val.id}`} class="theme-btn-four">Choose Plan</Link>
                                                        <div class="trial-text font-rubik">{val.limitation}</div>
                                                    </div>
                                                </div>
                                            )
                                           }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pricing-section-one bg-white">
                    <div class="fancy-hero-one">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class="font-rubik">Compare plans and features.</h2>
                                    <p class="sing-in-call">Taskflier Project Management <a href={`${getProdDevURL().REACT_APP_BASE_URL}/files/taskflier.pdf`} target='_blank' >Download PDF</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="bubble-one"></div>
                        <div class="bubble-two"></div>
                        <div class="bubble-three"></div>
                        <div class="bubble-four"></div>
                        <div class="bubble-five"></div>
                        <div class="bubble-six"></div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12">
                                <table>
                                    <tbody><tr>
                                        <th class="bg-th width-40">
                                            <h5 class="text-uppercase">MNB Project Packages</h5>
                                            <div class="plan-f">(No Monthly Contract Require )</div>
                                        </th>
                                        {Package && Package.map((val) => {
                                            return (
                                                <th class="bg-th width-20">
                                                    <h5 class="text-uppercase">{val.plan ?? ''}</h5>
                                                    <div class="plan-price">
                                                        <super>₹</super>{val.amount ?? ''}
                                                    </div>
                                                    <div class="plan-f">{val.limitation}</div>
                                                </th>
                                            )
                                        })}
                                        {/* <th class="bg-th width-20">
                                            <h5 class="text-uppercase">Starter</h5>
                                            <div class="plan-price">
                                                <super>₹</super>99
                                            </div>
                                            <div class="plan-f">Start your 10-day trial</div>
                                        </th>
                                        <th class="bg-th width-20">
                                            <h5 class="text-uppercase">Reguler</h5>
                                            <div class="plan-price">
                                                <super>₹</super>149
                                            </div>
                                            <div class="plan-f">/user/month</div>
                                        </th>
                                        <th class="bg-th width-20">
                                            <h5 class="text-uppercase">Premium</h5>
                                            <div class="plan-price">
                                                <super>₹</super>199
                                            </div>
                                            <div class="plan-f">/user/month</div>
                                        </th> */}
                                    </tr>
                                    </tbody>
                                </table>
                                <div id="accordion" class="md-mt-60 project">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> THE ESSENTIALS </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" className="accordion-collapse collapse " data-bs-parent="#accordion" >
                                            <div class="card-body">
                                                <table>
                                                    <tbody><tr>
                                                        <td class="text-left width-40"> Projects </td>
                                                        <td class="width-20"> 2 </td>
                                                        <td class="width-20"> Unlimited </td>
                                                        <td class="width-20"> Unlimited </td>
                                                    </tr>
                                                        <tr>
                                                            <td class="text-left"> Storage Space </td>
                                                            <td> 5GB </td>
                                                            <td> 100GB </td>
                                                            <td> 120GB </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left"> Project Templates </td>
                                                            <td> - </td>
                                                            <td> 20 </td>
                                                            <td> 30 </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left"> Read-only users </td>
                                                            <td> - </td>
                                                            <td> - </td>
                                                            <td> 10 </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"> CORE FEATURES</button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="accordion-collapse collapse show " data-bs-parent="#accordion">
                                            <div class="card-body">
                                                <table>
                                                    <tbody><tr>
                                                        <td class="text-left width-40">Dashboard </td>
                                                        <td class="width-20"> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        <td class="width-20"> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        <td class="width-20"> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                    </tr>
                                                        <tr>
                                                            <td class="text-left">Projects </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">HRMS
                                                            </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Employees
                                                            </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Clients </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Vendors
                                                            </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="text-left">Tickets
                                                            </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="text-left">Report
                                                            </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="text-left">Appointments
                                                            </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Lead Managment
                                                            </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Collections
                                                            </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Project expense
                                                            </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi-x-square textfalse font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Quotation </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Office expenses
                                                            </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Recurring activities
                                                            </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left">Settings
                                                            </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"> ISSUE TRACKER </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="accordion-collapse collapse " data-bs-parent="#accordion">
                                            <div class="card-body">
                                                <table>
                                                    <tbody><tr>
                                                        <td class="text-left width-40"> Business Rules </td>
                                                        <td class="width-20"> - </td>
                                                        <td class="width-20"> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        <td class="width-20"> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                    </tr>
                                                        <tr>
                                                            <td class="text-left"> Web to Issue Form </td>
                                                            <td> - </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left"> Link Issues </td>
                                                            <td> - </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left"> Custom Fields </td>
                                                            <td> - </td>
                                                            <td> - </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left"> SLA </td>
                                                            <td> - </td>
                                                            <td> - </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-left"> Webhooks </td>
                                                            <td> - </td>
                                                            <td> - </td>
                                                            <td> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour"> IN-HOUSE INTEGRATIONS </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="accordion-collapse collapse " data-bs-parent="#accordion">
                                            <div class="card-body">
                                                <table>
                                                    <tbody><tr>
                                                        <td class="text-left width-40"> MNB Project CRM </td>
                                                        <td class="width-20"> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        <td class="width-20"> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                        <td class="width-20"> <i class="bi bi bi-check2-square texttrue font-28"></i> </td>
                                                    </tr>

                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>



                <div className="footer-bg-wrapper bg-white">
                    <div className="bubble-one" />
                    <div className="bubble-two" />
                    <div className="bubble-three" />
                    <div className="fancy-short-banner-two">
                        <div className="container">
                            <div className="content-wrapper">
                                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                                    <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                                    <Link
                                        to="https://mnbproject.com/#"
                                        data-toggle="modal"
                                        data-target="#contactModal"
                                    >
                                        Request Demo
                                    </Link>
                                    <div className="bubble-one" />
                                    <div className="bubble-two" />
                                    <div className="bubble-three" />
                                    <div className="bubble-four" />
                                    <div className="bubble-five" />
                                </div>

                            </div>

                        </div>

                    </div>

                    <Footer />

                </div>

                <div
                    className="modal fade modal-contact-popup-one"
                    id="contactModal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="contactModalTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <main className="main-body modal-content clearfix">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <img src="./HomePage_files/close.svg" alt="" />
                            </button>
                            <div className="right-side">
                                <p className="form-pre">Safe &amp; Confidential</p>
                                <h2 className="form-title">Request For a Free Demo</h2>
                                <form  className="submitrequest">
                                    <div className="messages" />
                                    <div className="row controls">
                                        <div className="col-12">
                                            <div className="input-group-meta form-group mb-15">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    placeholder="Your Name"
                                                    name="firstname"
                                                    id="name"
                                                    required="required"
                                                    data-error="Name is required."
                                                />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group-meta form-group mb-15">
                                                <label>Email*</label>
                                                <input
                                                    type="email"
                                                    placeholder="Email Address"
                                                    name="email"
                                                    id="email"
                                                    required="required"
                                                    data-error="Valid email is required."
                                                />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group-meta form-group mb-15">
                                                <label>Phone*</label>
                                                <input
                                                    type="number"
                                                    placeholder="Phone No."
                                                    name="phone"
                                                    id="phone"
                                                    required="required"
                                                    data-error="Valid phone no. is required."
                                                />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group-meta form-group mb-35">
                                                <label>Apply For*</label>
                                                <select
                                                    name="service"
                                                    data-error="Valid Category is required."
                                                    id="subject"
                                                    
                                                >
                                                    <option value="">-- Select Services --</option>
                                                    <option value="IT Services">IT Services</option>
                                                    <option value="Charted Accountant">Charted Accountant</option>
                                                    <option value="Commerce">Commerce</option>
                                                    <option value="Construction">Construction</option>
                                                    <option value="Textiles">Textiles</option>
                                                    <option value="Electrical">Electrical</option>
                                                </select>
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button  type='submit' className="theme-btn-seven w-100">
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </div>
                <button className="scroll-top" style={{ display: "none" }}>
                    <i className="fa fa-angle-up" aria-hidden="true" />
                </button>
                











 
            </div>
        </>

    );
}
