import React from "react";

export default function TablePlaceholder({ colNum }) {
  const temp = new Array(colNum);
  for (let i = 0; i < colNum; i++) {
    temp[i] = i;
  }

  return (
    <>
      <tbody>
        <tr className="text-black fs-5">
          {temp.map((elem, index) => (
            <td key={index}>
              <p className="placeholder-glow">
                <span className="placeholder col-12"></span>
              </p>
            </td>
          ))}
        </tr>

        <tr className="text-black fs-5">
          {temp.map((elem, index) => (
            <td key={index}>
              <p className="placeholder-glow">
                <span className="placeholder col-12"></span>
              </p>
            </td>
          ))}
        </tr>
        <tr className="text-black fs-5">
          {temp.map((elem, index) => (
            <td key={index}>
              <p className="placeholder-glow">
                <span className="placeholder col-12"></span>
              </p>
            </td>
          ))}
        </tr>
        <tr className="text-black fs-5">
          {temp.map((elem, index) => (
            <td key={index}>
              <p className="placeholder-glow">
                <span className="placeholder col-12"></span>
              </p>
            </td>
          ))}
        </tr>
      </tbody>
    </>
  );
}
