import React, { useEffect, useState } from "react";
import { axiosInstance, dateOption } from "../../../helper";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import TablePlaceholder from "../../Placeholders.jsx/TablePlaceholder";


export default function EmployeeAllownaceDetail() {

  const [isLoading, setIsLoading] = useState([]);
  const [allowanceData, setAllowanceData] = useState([]);
  const [addExpensePopupOpen, setAddExpensePopupOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [expenseFormData, setexpenseFormData] = useState({
    amount: "",
    remark: "",
  });

  // Fetch Allowance Data
  const fetchAllowanceData = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get("/emp/allowances");

      console.log("allownace res: ", res);

      if (res.data.status === "success") {
        setAllowanceData(res.data.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Server Error: Can not fetch Allowance Data");
    } finally {
      setIsLoading(false);
    }
  };


  const handleExpenseFormChange = (e) => {
    // if file then set file and set profilePice name
    if (e.target.files) {
      setFile(e.target.files[0]);
      console.log("file add hui");
    }

    let name = e.target.name;
    let value = e.target.value;

    setexpenseFormData((prev) => ({ ...prev, [name]: value }));
  };


  // Submit Allowance Data 
  const submitAllowanceData = async (e) => {

    e.preventDefault();
    setIsLoading(true);

    const maxSizeInBytes = 1048576

    if (file && file?.size > maxSizeInBytes) {
      return toast.info('File size exceeds the limit of 1 MB');
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("expenseData", JSON.stringify(expenseFormData));

    try {
      const res = await axiosInstance.post("/emp/allowance", formData);

      if (res.data.status === "success") {
        toast.success(res.data.message)
      } else if (res.data.status === "error") {
        toast.success(res.data.message)
      }

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setAddExpensePopupOpen(false);
      fetchAllowanceData();
    }
  }

  useEffect(() => {
    fetchAllowanceData();
  }, [])



  return (
    <div class="content">
      <div class="container-fluid">
        <div class="card-header d-sm-flex align-items-center  justify-content-between">
          <h4 class="card-title flex-fill">Allowance</h4>
          <button
            className="btn btn-sm btn-primary"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#AddAllowance"
            onClick={() => setAddExpensePopupOpen(true)}
          >
            Add Expense
          </button>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card  animate__animated animate__bounceIn widget-flat atendance">
              <div class="card-body">
                <div class="float-end">
                  <i class="bi bi-bar-chart-fill widget-icon bg-primary-lighten text-primary"></i>
                </div>
                <h5 class="text-muted fw-500 mt-0">Total Credit</h5>
                {/* <h3 class="mt-2 mb-0  text-primary font-16">₹{allowanceData?}</h3> */}
                <h3 class="mt-2 mb-0  text-primary font-16">
                  {
                    isLoading ? null
                      :
                      `₹${allowanceData?.allowanceAmount ?? 0}`
                  }
                </h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card  animate__animated animate__bounceIn widget-flat atendance">
              <div class="card-body">
                <div class="float-end">
                  <i class="bi bi-bar-chart-fill widget-icon bg-danger-lighten text-danger"></i>
                </div>
                <h5 class="text-muted fw-500 mt-0">Total Expense</h5>
                {/* <h3 class="mt-2 mb-0 text-danger font-16">₹2000000.00</h3> */}
                <h3 class="mt-2 mb-0 text-danger font-16">
                  {
                    isLoading ? null
                      :
                      `₹${allowanceData?.allowanceExpense ?? 0}`
                  }
                </h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card  animate__animated animate__bounceIn widget-flat atendance">
              <div class="card-body">
                <div class="float-end">
                  <i class="bi bi-bar-chart-fill widget-icon bg-success-lighten text-success"></i>
                </div>
                <h5 class="text-muted fw-500 mt-0">Current Balance</h5>
                {/* <h3 class="mt-2 mb-0  text-success font-16">₹5000.00</h3> */}
                <h3 class="mt-2 mb-0  text-success font-16">
                  {
                    isLoading ? null
                      :
                      `₹${allowanceData?.allowanceBalance ?? 0}`
                  }
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card custom-card mb-3">
              <div class="card-header justify-content-between">
                <div class="card-title d-flex gap-3">
                  <span>Allowance Details</span>
                </div>
              </div>
              <div class="card-body py-2 px-2">
                <div class="table-responsive">
                  <table class="table text-nowrap table-hover border table-bordered">
                    <thead>
                      <tr>
                        <th>S No.</th>
                        <th>Date</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                        <th>Remarks</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    {
                      isLoading ?
                        <TablePlaceholder colNum={6} />
                        :
                        (
                          <tbody>
                            {
                              allowanceData?.allowanceLogsData?.map((log, index) => {
                                return (
                                  <tr key={log._id}>
                                    <td class="fw-500">{index + 1}</td>
                                    <td class="fw-500">{new Date(log.createdAt).toLocaleString("en-IN", dateOption)}</td>
                                    <td class="text-success">{log?.role === 1 ? `₹${log.amount}` : ""}</td>
                                    <td class="text-danger">{log?.role === 2 ? `₹${log.amount}` : ""}</td>
                                    <td class="text-success">₹{log?.currentBalance ?? 0}</td>
                                    <td>{log?.remark ?? ""}</td>
                                    {/* <td>
                                      <button class="btn font-12  btn-sm btn-danger">
                                        <i class="bi bi-trash3"></i>
                                      </button>
                                    </td> */}
                                  </tr>
                                )
                              })
                            }
                            {/* <tr>
                              <td class="fw-500">01</td>
                              <td class="fw-500">13-Jul-2024</td>
                              <td class="text-danger">₹15000.00</td>
                              <td class="text-success">₹5000.00</td>
                              <td class="text-success">₹5000.00</td>
                              <td>Petrol</td>
                              <td>
                                <button class="btn font-12  btn-sm btn-danger">
                                  <i class="bi bi-trash3"></i>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td class="fw-500">02</td>
                              <td class="fw-500">12-Jul-2024</td>
                              <td className="text-danger">₹20000.00</td>
                              <td class="text-success">₹20000.00</td>
                              <td class="text-success">₹20000.00</td>
                              <td>Grocery</td>
                              <td>
                                <button class="btn font-12  btn-sm btn-danger">
                                  <i class="bi bi-trash3"></i>
                                </button>
                              </td>
                            </tr> */}
                          </tbody>
                        )
                    }

                  </table>
                </div>
              </div>
              <div class="row mb-3">
                <ul class="pagination justify-content-center small mb-0">
                  <li class="page-item disabled">
                    <a class="page-link" href="/" aria-label="Go to first page">
                      First
                    </a>
                  </li>
                  <li class="page-item disabled">
                    <a
                      class="page-link"
                      href="/"
                      aria-label="Go to previous page"
                    >
                      ⟨
                    </a>
                  </li>
                  <li class="page-item disabled">
                    <a class="page-link" href="/" aria-label="Go to next page">
                      ⟩
                    </a>
                  </li>
                  <li class="page-item disabled">
                    <a class="page-link" href="/" aria-label="Go to last page">
                      Last
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal blurmodal fade "
        id="AddAllowance"
        tabindex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h1 class="modal-title text-white fs-5" id="exampleModalLabel">
                Add Expense
              </h1>
              <button
                type="button"
                class="btn-close bg-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="card-body py-0 px-0 input">
                <form onSubmit={submitAllowanceData} enctype="multipart/form-data">
                  <div class="row">
                    <div className="col-md-12 mb-2">
                      <label htmlFor="amount" className="form-label">
                        Amount
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="amount"
                        placeholder="Enter Amount"
                        name="amount"
                        value={expenseFormData.amount}
                        onChange={handleExpenseFormChange}
                      />
                    </div>

                    <div className="col-md-12 mb-2">
                      <label htmlFor="remark" className="form-label">
                        Remarks
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="remark"
                        placeholder="Enter Remarks"
                        name="remark"
                        value={expenseFormData.remark}
                        onChange={handleExpenseFormChange}
                      />
                    </div>

                    <div className="col-md-12 mb-2">
                      <label htmlFor="file" className="form-label">
                        Expense Slip
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="file"
                        placeholder="Enter Remarks"
                        accept="image/*"
                        onChange={handleExpenseFormChange}
                      />
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <button
                        type="button"
                        class="btn btn-sm btn-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="bi bi-backspace"></i> <span>Back</span>
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-success ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setAddExpensePopupOpen(false)}
                      >
                        <div class="d-flex gap-2">
                          <i class="bi bi-save"></i>
                          <span>Submit</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
