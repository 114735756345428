import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./View/style.css";
import AdminRoutes from './View/Admin/AdminRoutes';
import UserRoutes from './View/User/UserRoutes';
import EmployeeRoutes from './View/Employee/EmployeeRoutes';
import SuperAdminRoutes from './View/SupAdmin/SuperAdminRoutes';
import AppRoutes from './View/Public/AppRoutes';
import axios from "axios";
import jwtDecode from "jwt-decode";
// Components
import DashboardHeader from './View/Components/Header';
import DashboardFooter from './View/Components/Footer';
import NoInternetConnection from './View/Public/NoInternetConnection'
import { getProdDevURL } from "./helper";




const App = () => {
  const navigate = useNavigate();
  const http = document.location.pathname.toLowerCase().split('/');
  const token = localStorage.getItem("token");
  const decoded = token && jwtDecode(token);

  const location = useLocation();

  
  const Routes = ({ x }) => {

    return useRoutes(
      http[1] ?
        http[1] === 'admin' && http[2] && decoded.role === 1 ?
          AdminRoutes
          :
          http[1] === 'employee' && http[2] && decoded.role === 2 ?
            EmployeeRoutes
            :
            http[1] === 'user' && http[2] && decoded.role === 3 ?
              UserRoutes
              :
              http[1] === 'supadmin' && http[2] && decoded.role === 0 ?
                SuperAdminRoutes
                :
                AppRoutes
        :
        AppRoutes
    );

  };

  useEffect(()=>{
    // if location is at root and the user has token then redirect it to dashboard
    if(location.pathname === "/"){

      const token = localStorage.getItem("token");
      
      if(token){
        const decodedToken = jwtDecode(token);

        if(decodedToken){
          console.log(decodedToken?.role);
          if(decodedToken?.role === 0){
            navigate("/supadmin/dashboard")
          }else if(decodedToken?.role === 1){
            navigate("/admin/dashboard")
          }else if(decodedToken?.role === 2){
            navigate("/employee/dashboard")
          }else if(decodedToken?.role === 3){
            navigate("/user/dashboard")
          }
  
        }
  
      }

    }
    
  }, []);

  


  return (
    <>
      <NoInternetConnection>
        <ToastContainer />
        {
          (http[1] === 'admin' && http[2]) || (http[1] === 'user' && http[2]) || (http[1] === 'employee' && http[2]) || (http[1] === 'supadmin' && http[2]) ?
            <div className="wrapper" >
              <DashboardHeader />
              <div className="content-page">
                <div className="content"  >
                  <Suspense fallback='' >
                    {
                      decoded === null
                        ? <Navigate to={"/"} replace={true} />
                        : <Routes x={"hello"} />
                    }

                  </Suspense>
                </div>
                {/* <DashboardFooter/> */}
              </div>
            </div>
            :
            <><Suspense fallback=''><Routes /></Suspense></>
        }
      </NoInternetConnection>
    </>
  );
}

export default App;
