import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Contactus() {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <>

<Helmet>
      <title>Contact Taskflier - Get in Touch with Our Expert Team Today </title>
      <meta name="description" content="Need help with your tasks? Contact Taskflier for reliable and efficient solutions. Our expert team is ready to assist you. Visit our website now!
" />
      </Helmet>
         {" "}
         <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
         <link
            rel="stylesheet"
            type="text/css"
            href="/main/css/responsive.css"
         ></link>
         <div className="main-page-wrapper font-rubik">
            <Header />

            <div class="fancy-hero-four space-fix">
               <div class="shapes shape-one"></div>
               <div class="shapes shape-two"></div>
               <div class="shapes shape-three"></div>
               <div class="shapes shape-four"></div>
               <div class="shapes shape-five"></div>
               <div class="shapes shape-six"></div>
               <div class="bg-wrapper">
                  <div class="container">
                     <div class="row">
                        <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
                           <h6>Contact us</h6>
                           <h2>Feel free to contact us or just say hi!</h2>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
            <div class="contact-style-two bg-white">
               <div class="container">
                  <div class="contact-info-wrapper">
                     <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6 d-lg-flex">
                           <div class="address-info">
                              <div class="icon d-flex align-items-end"><img src="images/icon/44.svg" alt="" /></div>
                              <div class="title">Head Office</div>
                              <p class="font-rubik">A - 1612 Tower 3, NX One Techzone 4 Greater Noida, Uttar Pradesh 201308, India</p>
                           </div>

                        </div>
                        <div class="col-lg-4 col-sm-6 d-lg-flex">
                           <div class="address-info">
                              <div class="icon d-flex align-items-end"><img src="images/icon/44.svg" alt="" /></div>
                              <div class="title">Corporate Office</div>
                              <p class="font-rubik">5th Block, 1217, Chord Rd, Near Shivanagar Signal, Rajajinagar, Bengaluru, Karnataka 560010</p>
                           </div>

                        </div>
                        <div class="col-lg-4 col-sm-6 d-lg-flex">
                           <div class="address-info">
                              <div class="icon d-flex align-items-end"><img src="images/icon/46.svg" alt="" /></div>
                              <div class="title">Connect us</div>

                              <p class="font-rubik">+91 93112 21776</p>
                              <p class="font-rubik">+91 99946 57491</p>
                              <p class="font-rubik">support@taskflier.com</p>


                           </div>

                        </div>
                     </div>
                     {/* <img src="images/shape/64.svg" alt="" class="shapes shape-one"/>
         <img src="images/shape/65.svg" alt="" class="shapes shape-two"/> */}
                  </div>



                  <div class="form-style-light">
                     <form action="#" id="contact-form" data-toggle="validator" novalidate="true">
                        <div class="messages"></div>
                        <div class="row controls">
                           <div class="col-md-6">
                              <div class="input-group-meta form-group mb-35">
                                 <label>First Name</label>
                                 <input type="text" placeholder="Michel" name="Fname" required="required" data-error="Name is required." />
                                 <span class="placeholder_icon valid-sign"><img src="images/icon/18.svg" alt="" /></span>
                                 <div class="help-block with-errors"></div>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="input-group-meta form-group mb-35">
                                 <label>Mobile No</label>
                                 <input type="text" placeholder="000-000-0000" name="Lname" required="required" data-error="Name is required." />
                                 <span class="placeholder_icon valid-sign"><img src="images/icon/18.svg" alt="" /></span>
                                 <div class="help-block with-errors"></div>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="input-group-meta form-group mb-35">
                                 <label>Your Email</label>
                                 <input type="email" placeholder="example@gmail.com" name="email" required="required" data-error="Valid email is required." />
                                 <span class="placeholder_icon valid-sign"><img src="images/icon/18.svg" alt="" /></span>
                                 <div class="help-block with-errors"></div>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="input-group-meta form-group lg mb-35">
                                 <label>Your Message</label>
                                 <textarea placeholder="Write your message here..." name="message" required="required" data-error="Please,leave us a message."></textarea>
                                 <div class="help-block with-errors"></div>
                              </div>
                           </div>
                           <div class="col-12"><button class="theme-btn-one btn-lg">Send Message</button></div>
                        </div>
                     </form>
                  </div>


               </div>
            </div>

            <div className="footer-bg-wrapper bg-white">
               <div className="bubble-one" />
               <div className="bubble-two" />
               <div className="bubble-three" />
               <div className="fancy-short-banner-two">
                  <div className="container">
                     <div className="content-wrapper">
                        <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                           <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                           <Link
                              to="https://mnbproject.com/#"
                              data-toggle="modal"
                              data-target="#contactModal"
                           >
                              Request Demo
                           </Link>
                           <div className="bubble-one" />
                           <div className="bubble-two" />
                           <div className="bubble-three" />
                           <div className="bubble-four" />
                           <div className="bubble-five" />
                        </div>

                     </div>

                  </div>

               </div>

               <Footer />

            </div>







            <button className="scroll-top" style={{ display: "none" }}>
               <i className="fa fa-angle-up" aria-hidden="true" />

            </button>




         </div>
      </>
   );
}

export default Contactus;