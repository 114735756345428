import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { axiosInstance } from "../../../helper";
function Aboutus() {

  const [seoData, setSeoData] = useState(null); 


  const fetchSeoData = async()=> {
    try {
      const res = await axiosInstance.get("/about");
      setSeoData(res.data)
      console.log(res);
      
    } catch (error) {
      console.log(error);
      
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSeoData();
    document.title = "This is about us from aboutus useeffect"
  }, []);


  console.log(typeof seoData);
  
 
  return (
    <>
    
      <Helmet>
        <title>About Our Company | Taskflier | A Trusted Platform for Outsourcing Project </title>
        <meta name="description" content="Our company offers efficient and reliable solutions for all your project needs. Learn more about us and how we can help your business grow." />
      </Helmet>

      {/* {seoData && ( // Render title only if data is fetched
        <Helmet>
          <title>{seoData.title}</title>
          <meta name="description" content={seoData.description} />
        </Helmet>
      )} */}

    {" "}
      <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
      <link rel="stylesheet" type="text/css" href="/main/css/responsive.css"></link>

      <div className="main-page-wrapper font-rubik">
        <Header />

        <div class="fancy-hero-five">
				<div class="bg-wrapper">
					<div class="container">
						<div class="text-center row">
							<div class="col-xl-9 m-auto">
								<h1 class="heading">About Us</h1>
								<p class="sub-heading space-xs  mt-15">We revolutionize task management with a unique blend of efficiency and security.</p>
							</div>
						</div>
					</div>
				</div>
		</div>


<div class="fancy-feature-sixteen pt-80 md-mt-100 bgwhite  " id="feature">
   <div class="container">
      <div class="title-style-twelve">
         <div class="row align-items-center">
            <div class="col-lg-5 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">

<p className="mainp">At Taskflier, we’re passionate about simplifying your task management experience. Our platform is designed to help you stay organized, boost productivity, and achieve your goals effortlessly. As a part of MNB Soft Solution, Taskflier benefits from the innovation, expertise, and resources of a leading name in technology solutions.</p>
<h3 className="maintitle">Why MNB Soft Solution Matters</h3>


               <div class="text-wrapper">
          <p className="mainp">We revolutionize task management with a unique blend of efficiency and security. Our platform is designed to streamline your workflow, making task organization and project tracking effortlessly intuitive. We're passionate about empowering you to achieve your goals with precision and ease.</p>
				  
				  <h3 class="maintitle">What sets us apart?</h3>
				  <p className="mainp">Our unwavering commitment to safeguarding your data. At Taskflier, your privacy is our top priority. We ensure that your information is protected with state-of-the-art security measures, so you can focus on what matters most—getting things done. Join us in experiencing a new era of task management, where productivity meets peace of mind.</p>
          

          </div>
               
            </div>
            <div class="col-lg-6 offset-lg-1" >
            <div class="screen-holder-two">
                  <img src="/main/images/project.jpg" alt=""/>
                 
               </div>
            </div>
			<div class="col-lg-12 mt-40">
               <div class="text-wrapper">
               <p className="mainp"><b>Heritage of Excellence</b>: MNB Soft Solution is known for its commitment to quality and innovation. With decades of experience in technology and solutions, MNB Soft Solution brings a legacy of excellence to Taskflier. This affiliation ensures that Taskflier is built on a foundation of trust, reliability, and cutting-edge technology.</p>

<p className="mainp"><b>Innovation and Resources</b>: Being part of MNB Soft Solution means Taskflier benefits from advanced technology, research, and development resources. This partnership allows us to continuously enhance our platform, integrating the latest features and ensuring that Taskflier remains at the forefront of task management technology.</p>

<p className="mainp"><b>Global Reach and Support</b>: MNB Soft Solution’s global presence and support infrastructure enable Taskflier to offer exceptional service and support to users worldwide. Whether you’re a small business or a large enterprise, our team is equipped to assist you with your task management needs.</p>


               <h3 className="maintitle">Our Key Features</h3>
<p className="mainp"><b>Intuitive Task Management</b>: Easily create, assign, and track tasks with our user-friendly interface. Taskflier’s design ensures that managing your workload is simple and efficient.</p>

<p className="mainp"><b>Customizable Workflows</b>: Adapt Taskflier to fit your unique needs with customizable workflows, task categories, and priority settings. Tailor the platform to suit your personal or organizational preferences.</p>

<p className="mainp"><b>Collaborative Tools</b>: Enhance team productivity with features that facilitate collaboration. Share tasks, set deadlines, and communicate with team members seamlessly within the platform.</p>

<p className="mainp"><b>Smart Integrations</b>: Connect Taskflier with your favorite tools and applications. Our platform integrates with popular software to streamline your workflow and keep all your tasks and projects in sync.</p>

<h3 className="maintitle">Our Commitment</h3>
<p className="mainp"><b>User-Centric Approach</b>: At Taskflier, your needs drive our development. We listen to your feedback and continuously improve our platform to ensure it meets your expectations and supports your goals.</p>

<p className="mainp"><b>Innovation and Growth</b>: We are dedicated to staying ahead of industry trends and integrating the latest technologies. Our goal is to provide a task management solution that evolves with the changing landscape of work.</p>

<p className="mainp"><b>Exceptional Support</b>: Our dedicated support team is here to assist you every step of the way. From onboarding to troubleshooting, we’re committed to providing the help you need to make the most of Taskflier.</p>

<h3 className="maintitle">Join Us</h3>
<p className="mainp">Experience the future of task management with Taskflier, backed by the expertise and innovation of MNB Soft Solution. Sign up today and discover how Taskflier can streamline your tasks, enhance your productivity, and help you achieve more.</p>

				  
               </div>
               
            </div>
         </div>
      </div>

      </div>
</div>

<div class="fancy-text-block-twenty bg-white pb-0 pt-100" style={{padding:"100px 0 0 0 "}}>
				
        <div class="container">
					
					<div class="row">
						<div class="col-lg-5 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
							<div class="client-info font-rubik">Over <span>150,000+ client</span></div>
							<div class="maintitle">
								<h2><span>Excellent work</span><br/> &amp;  environments combine individuals with testing issues.</h2>
							</div>
						</div>
						<div class="col-lg-6 " >
							
							<ul class="nav nav-tabs md-mt-50">
							  	<li class="nav-item" role="presentation">
							    	<a class="nav-link  active" data-bs-toggle="tab"  data-bs-target="#our-story" href="#our-story">Vision</a>
							  	</li>
							  	<li class="nav-item" role="presentation">
							    	<a class="nav-link " data-bs-toggle="tab" data-bs-target="#our-mission" href="#our-mission">Goal  </a>
							  	</li>
							  	<li class="nav-item" role="presentation">
							    	<a class="nav-link " data-bs-toggle="tab" data-bs-target="#our-vision" href="#our-vision">Objective</a>
							  	</li>
							</ul>

						
							
							<div class="tab-content mt-20">
							  	<div class="tab-pane fade active show" id="our-story">
							  		<p class="mainp">Taskflier aims to simplify and enhance the way people manage their tasks and projects. We envision a platform that not only makes task management easier but also helps users achieve their goals more efficiently. </p>
							  		<p class="mainp pt-0">By focusing on user-friendly design and powerful features, we want Taskflier to be the go-to solution for anyone looking to improve their productivity and workflow. Taskflier wants to change how people manage their tasks and projects. We see a future where our platform helps users easily keep track of what they need to do, stay organized, and work together with others smoothly.</p>
							  	</div>
							  	<div class="tab-pane fade" id="our-mission">
							  		<p class="mainp">Our goal is to create a transformative task management experience that not only enhances your productivity but also prioritizes your privacy at every turn. We aim to deliver a platform that is both powerful and intuitive, offering sophisticated tools to streamline your workflow while maintaining stringent security protocols. </p>
							  		<p class="mainp pt-0">By blending cutting-edge technology with a deep commitment to data protection, we strive to empower users to work smarter and more securely. Our mission is to provide a solution where you can confidently manage your tasks and projects, knowing that your data is safe, your workflow is optimized, and your ambitions are within reach.</p>
							  	</div>
							  	<div class="tab-pane " id="our-vision">
							  		<p class="mainp">The objective of Taskflier is to help users manage and track their tasks effectively. It allows users to create and organize tasks into projects or categories, which helps in managing various responsibilities. Users can assign priorities and set deadlines, aiding in effective time management and focus on high-priority tasks. </p>
							  		<p class="mainp pt-0">The platform sends reminders and notifications to keep users on track and reduce the risk of forgetting important deadlines.</p>
							  	</div>
							</div>

							

						</div>
					</div>
				</div>
			</div>


        <div className="footer-bg-wrapper bg-white">
          <div className="bubble-one" />
          <div className="bubble-two" />
          <div className="bubble-three" />
          <div className="fancy-short-banner-two">
            <div className="container">
              <div className="content-wrapper">
                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                  <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                  <Link
                    to="https://mnbproject.com/#"
                    data-toggle="modal"
                    data-target="#contactModal"
                  >
                    Request Demo
                  </Link>
                  <div className="bubble-one" />
                  <div className="bubble-two" />
                  <div className="bubble-three" />
                  <div className="bubble-four" />
                  <div className="bubble-five" />
                </div>
             
              </div>
             
            </div>
           
          </div>
       
          <Footer />
        
        </div>

<button className="scroll-top" style={{ display: "none" }}>
          <i className="fa fa-angle-up" aria-hidden="true" />
        
        </button>
        
        </div>
    </>
  );
}

export default Aboutus;