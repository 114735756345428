import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer class="theme-footer-one pt-130 md-pt-70">
        <div class="top-footer">
          <div class="container">
            <div class="row justify-content-end">
              <div
                class="col-lg-3 col-md-12 footer-about-widget aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <Link to="index.html" class="logo-main">
                  <img src="/main/images/logo/mnb-project.png" alt="" />
                </Link>
              </div>
              <div
                class="col-lg-3 col-md-4 footer-list aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="50"
              >
                <h5 class="footer-title">Company</h5>
                <ul>
                  <li>
                    <Link to="/Aboutus">About us </Link>
                  </li>
                  <li>
                    <Link to="/Customers">Customers </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact us </Link>
                  </li>
                  <li>
                    <Link to="/Solutions">Solutions</Link>
                  </li>
                  <li>
                    <Link to="/Features">Features</Link>
                  </li>
                 
                </ul>
              </div>
              <div
                class="col-lg-3 col-md-4 footer-list aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <h5 class="footer-title">Resources</h5>
                <ul>
                <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>

                  <li>
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Security">Security</Link>
                  </li>
                  <li>
                    <Link to="/Terms_of_Service">Terms of Service  </Link>
                  </li>
                  <li>
                    <Link to="/CookiePolicy">Cookie Policy</Link>
                  </li>
                 
                 
                </ul>
              </div>
              <div
                class="col-lg-3 col-md-4 address-list aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="150"
              >
                <h5 class="footer-title">Address</h5>
                <ul>
                  <li>
                    <Link to="#">support@taskflier.com</Link>
                  </li>
                  <li>
                    <Link to="#" class="mobile-num">
                    +91 93112 21776
                    </Link>
                  </li>
                  <li>
                  A - 1612 Tower 3, NX One Techzone 4 Greater Noida, Uttar Pradesh 201308, India
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

       
          <div class="bottom-footer-content">
          <div class="container">
            <div class="d-sm-flex align-items-center justify-content-between">
              <ul
                class="order-sm-last aos-init aos-animate"
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="100"
                data-aos-anchor=".theme-footer-one"
              >
                <li>
                  <Link to="/Privacy">Privacy</Link>
                </li>
                <li>
                  <Link to="/Security">Security</Link>
                </li>
                <li>
                  <Link to="/Terms_of_Service">Terms</Link>
                </li>
              </ul>
              <p
                class="order-sm-first aos-init aos-animate"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
                data-aos-anchor=".theme-footer-one"
              >
                Copyright @ 2024 TaskFlier.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
