import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

<Helmet>
      <title>Privacy Policy | Taskflier </title>
      <meta name="description" content="discription" />
      </Helmet>


      {" "}
      <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
      <link rel="stylesheet" type="text/css" href="/main/css/responsive.css"></link>

      <div className="main-page-wrapper font-rubik">
        <Header />

        <div class="fancy-hero-five">
				<div class="bg-wrapper">
					<div class="container">
						<div class="text-center row">
							<div class="col-xl-9 m-auto">
								<h1 class="heading">Privacy Policy</h1>
								<p class="sub-heading space-xs font-gordita mt-15"> We care about your privacy and want to make sure you understand how we handle your information. Here's our privacy policy in easy language</p>
							</div>
						</div>
					</div>
				</div>
		</div>

		


		<div class="fancy-feature-sixteen pt-80 md-mt-100 bgwhite  " id="feature">
   <div class="container">
      <div class="title-style-twelve">
         <div class="row align-items-center">
            <div class="col-lg-12 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
               <div class="text-wrapper">


<h3 class="maintitle3 mt-0">1. Introduction</h3>
<p className="mainp3">Welcome to Task Flier! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our services.
</p>
<h3 class="maintitle3">2. Information We Collect</h3>
<p className="mainp3">We collect two types of information:</p>

<p className="mainp3"><b>Personal Information</b>: This includes your name, email address, phone number, and payment details when you sign up or use our services.</p>
<p className="mainp3"><b>Usage Information</b>: We collect data on how you interact with our app, including the tasks you post, your communications, and device information like IP address and browser type.</p>

<h3 class="maintitle3">3. How We Use Your Information</h3>
<p className="mainp3">We use your information to:</p>

<p className="mainp3">Provide and improve our services.</p>
<p className="mainp3">Process payments and manage your account.</p>
<p className="mainp3">Communicate with you about your account or transactions.</p>
<p className="mainp3">Protect against fraud and ensure the security of our platform.</p>

<h3 class="maintitle3">4. Sharing Your Information</h3>
<p className="mainp3">We only share your personal information with third parties when necessary:</p>

<p className="mainp3"><b>Service Providers:</b> We may share information with trusted partners who assist in delivering our services, like payment processors.</p>
<p className="mainp3"><b>Legal Requirements:</b> If required by law, we may disclose your information to comply with legal obligations.</p>
<h3 class="maintitle3">5. Your Choices and Rights</h3>
<p className="mainp3">You have control over your information:</p>

<p className="mainp3"><b>Access and Update</b>: You can view and update your personal information at any time through your account settings.</p>
<p className="mainp3"><b>Opt-Out</b>: You can opt out of receiving marketing communications from us.</p>
<p className="mainp3"><b>Delete</b>: You can request the deletion of your account and personal information.</p>

<h3 class="maintitle3 ">6. Security</h3>
<p className="mainp3">We take security seriously and use various measures to protect your information from unauthorized access, including encryption and secure servers.</p>

<h3 class="maintitle3">7. Cookies and Tracking Technologies</h3>
<p className="mainp3">Task Flier uses cookies to enhance your experience. Cookies help us remember your preferences and track how you use our services. You can manage your cookie preferences through your browser settings.</p>

<h3 class="maintitle3">8. Changes to This Policy</h3>
<p className="mainp3">We may update this Privacy Policy from time to time. If we make significant changes, we'll notify you through the app or by email.
</p>
<h3 class="maintitle3">9. Contact Us</h3>
<p className="mainp3">If you have any questions about this Privacy Policy, please contact us at - Email:<b>support@taskflier.com</b> </p>

</div>
               
            </div>
           
			
         </div>
      </div>
     
    

</div>
</div>


        <div className="footer-bg-wrapper bg-white">
          <div className="bubble-one" />
          <div className="bubble-two" />
          <div className="bubble-three" />
          <div className="fancy-short-banner-two">
            <div className="container">
              <div className="content-wrapper">
                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                  <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                  <Link
                    to="https://mnbproject.com/#"
                    data-toggle="modal"
                    data-target="#contactModal"
                  >
                    Request Demo
                  </Link>
                  <div className="bubble-one" />
                  <div className="bubble-two" />
                  <div className="bubble-three" />
                  <div className="bubble-four" />
                  <div className="bubble-five" />
                </div>
             
              </div>
             
            </div>
           
          </div>
       
          <Footer />
        
        </div>



       



        <button className="scroll-top" style={{ display: "none" }}>
          <i className="fa fa-angle-up" aria-hidden="true" />
        
        </button>




      </div>
    </>
  );
}

export default Privacy;