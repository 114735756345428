import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getProdDevURL } from "../../../helper";

export default function Header() {
  const navigate = useNavigate();
  const closeModalRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reqDemoData, setReqDemoData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    service: "",
  });

  const [validateReqDemoData, setValidateReqDemoData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    service: "",
  });

  // handle request demo form data change
  const handleReqDemoDataChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "email") {
      e.target.value = e.target.value.toLowerCase();
    }

    setValidateReqDemoData((prev) => ({
      ...prev,
      [name]: "",
    }));

    setReqDemoData((prev) => ({
      ...prev,
      [name]: name === "email" ? value?.toLowerCase() : value,
    }));
  };

  function isValidFirstNameClient(name) {
    const validNamePattern = /^[A-Za-z ]*$/;
    return name === "" || validNamePattern.test(name);
  }

  function isValidEmailClient(email) {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    return emailPattern.test(email);
  }

  function isValidMobileNumberClient(number) {
    const mobileNumberPattern = /^(\+\d{1,3})?[6-9]\d{9}$/;

    return mobileNumberPattern.test(number);
  }


  

  // POST request demo data
  const submitReqDemoData = async (e) => {
    e.preventDefault();

    if (
      !reqDemoData?.firstName ||
      !isValidFirstNameClient(reqDemoData?.firstName) ||
      !isValidFirstNameClient(reqDemoData?.lastName) ||
      !isValidEmailClient(reqDemoData?.email) ||
      !isValidMobileNumberClient(reqDemoData?.mobile) ||
      !reqDemoData?.service
    ) {
      setValidateReqDemoData({
        firstName: !reqDemoData?.firstName
          ? "First name is required"
          : !isValidFirstNameClient(reqDemoData?.firstName)
          ? "Invalid first name"
          : null,

        lastName: !isValidFirstNameClient(reqDemoData?.lastName)
          ? "Invalid last name"
          : null,

        mobile: !reqDemoData?.mobile
          ? "Mobile number is required"
          : !isValidMobileNumberClient(reqDemoData?.mobile)
          ? "Invalid mobile number"
          : null,
        email: !reqDemoData?.email
          ? "Email is required"
          : !isValidEmailClient(reqDemoData?.email)
          ? "Invalid email"
          : null,
        service: !reqDemoData?.service ? "Service is required" : null,
      });

      return;
    }
    setIsSubmitting(true);
    // console.log(reqDemoData);

    try {
      // const res = await axios.post("https://phplaravel-979432-4660383.cloudwaysapps.com/api/taskflier/enquiry", reqDemoData);

      // const res = await axios.post(`${getProdDevURL().REACT_APP_API_URL}/public/req/demo`, reqDemoData);

      const res = await axios.post(
        `${getProdDevURL().REACT_APP_API_URL}/public/req/demo/enquiry`,
        reqDemoData
      );

      console.log(res);
      if (res.data.status === "success") {
        setReqDemoData({
          firstName: "",
          lastName: "",
          mobile: "",
          email: "",
          service: "",
        });
        toast.success(res.data.message);

        navigate("/enquiry-raised");
        closeModalRef.current.click();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    window.AOS.init({});
  });

  return (
    <>
      <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
      <link
        rel="stylesheet"
        type="text/css"
        href="/main/css/responsive.css"
      ></link>
      <div className="theme-main-menu sticky-menu theme-menu-one">
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo-main">
            <Link to="/">
              <img src="/main/images/logo/mnb-project.png" alt="" />
            </Link>
          </div>
          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="nav-container">
              <button
                className="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span />
              </button>

              <div
                class="navbar-collapse collapse "
                id="navbarSupportedContent"
              >
                <div class="d-lg-flex align-items-center">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <Link class="nav-link " to="/Aboutus">
                        About us
                      </Link>
                    </li>

                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                      >
                        Products
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <Link to="/Capabilities" class="dropdown-item">
                            Capabilities
                          </Link>
                        </li>
                        <li>
                          <Link to="/Features" class="dropdown-item">
                            All features
                          </Link>
                        </li>
                        <li>
                          <Link to="/Integrations" class="dropdown-item">
                            Integrations
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <Link to="/Solutions" class="nav-link">
                        Solutions
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/Customers" class="nav-link">
                        Customers
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/pricing">
                        Pricing
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>

                  <ul class="right-button-group d-flex align-items-center justify-content-center">
                    <li>
                      <Link to={"/login"} class="signIn-action">
                        Login
                      </Link>
                    </li>
                    <li>
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#contactModal"
                        class="btn signUp-action"
                      >
                        Request Demo
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div
        className="modal fade modal-contact-popup-one"
        id="contactModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="contactModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <main className="main-body modal-content clearfix">
            <button
              ref={closeModalRef}
              type="button"
              className="close btnmodl"
              data-bs-dismiss="modal"
              aria-bs-label="close"
            >
              <img src="/main/images/icon/close.svg" alt="" />
            </button>
            <div className="headpopup">
              {" "}
              <p className="form-pre">Safe &amp; Confidential</p>
              <h2 className="form-title">Request For a Free Demo</h2>
            </div>
            <div className="right-side">
              <form onSubmit={submitReqDemoData} className="submitrequest">
                <div className="messages" />
                <div className="row controls">
                  <div className="col-6">
                    <div className="input-group-meta form-group mb-15">
                      <label>First Name*</label>
                      <input
                        type="text"
                        placeholder="Your First Name"
                        name="firstName"
                        id="lastname"
                        data-error="Name is required."
                        onChange={handleReqDemoDataChange}
                        value={reqDemoData?.firstName}
                      />

                      {/* <div className="help-block with-errors" /> */}
                      <p className="text-danger small text-center">
                        {validateReqDemoData?.firstName}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group-meta form-group mb-15">
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder="Your Last Name"
                        name="lastName"
                        id="lastname"
                        data-error="Name is required."
                        onChange={handleReqDemoDataChange}
                        value={reqDemoData?.lastName}
                      />
                      <p className="text-danger small text-center">
                        {validateReqDemoData?.lastName}
                      </p>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta  form-group mb-15">
                      <label>Email*</label>
                      <input
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        id="email"
                        data-error="Valid email is required."
                        onChange={handleReqDemoDataChange}
                        value={reqDemoData?.email}
                      />
                      <p className="text-danger small text-center">
                        {validateReqDemoData?.email}
                      </p>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-15">
                      <label>Phone*</label>
                      <input
                        type="number"
                        placeholder="Phone No."
                        name="mobile"
                        id="phone"
                        data-error="Valid phone no. is required."
                        onChange={handleReqDemoDataChange}
                        value={reqDemoData?.mobile}
                      />
                      <p className="text-danger small text-center">
                        {validateReqDemoData?.mobile}
                      </p>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-35">
                      <label>Apply For*</label>
                      <select
                        name="service"
                        data-error="Valid Category is required."
                        id="subject"
                        onChange={handleReqDemoDataChange}
                      >
                        <option
                          selected={reqDemoData?.service == "" ? true : false}
                          value=""
                        >
                          -- Select Services --
                        </option>
                        <option
                          // selected={
                          //   reqDemoData?.service == "IT Services" ? true : false
                          // }

                          value="IT Services"
                        >
                          IT Services
                        </option>
                        <option value="Charted Accountant">
                          Charted Accountant
                        </option>
                        <option value="Commerce">Commerce</option>
                        <option value="Construction">Construction</option>
                        <option value="Textiles">Textiles</option>
                        <option value="Electrical">Electrical</option>
                        <option value="Franchise ">Franchise</option>
                      </select>
                      <p className="text-danger small text-center">
                        {validateReqDemoData?.service}
                      </p>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>
                  <div className="col-12">
                    {!isSubmitting ? (
                      <button type="submit" className="theme-btn-seven w-100">
                        Send Message
                      </button>
                    ) : (
                      <button
                        className="theme-btn-seven w-100"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
