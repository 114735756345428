import React from "react";
import EmployeeDoc from "./HRMS/EmployeeDoc.jsx";
import Employeedetails from "./Employee/Employeedetails.jsx";

const Designations = React.lazy(() =>
  import("./Settings/Designation/Designations.jsx")
);
const Documents = React.lazy(() =>
  import("./Settings/Documents/Documents.jsx")
);
const Educations = React.lazy(() =>
  import("./Settings/Education/Educations.jsx")
);
const Dashboard = React.lazy(() => import("./Dashboard/Dashboard"));
const Employees = React.lazy(() => import("./Employee/employees"));
const ManageEmployee = React.lazy(() => import("./Employee/manag-employee"));
const Clients = React.lazy(() => import("./Client/clients"));
const ManageClient = React.lazy(() => import("./Client/manage-client"));
const Vendors = React.lazy(() => import("./Vendor/vendors"));
const ManageVendor = React.lazy(() => import("./Vendor/manage-vendor"));
const Projects = React.lazy(() => import("./Project/projects"));
const Ledger = React.lazy(() => import("./Project/Ledger"));
const Project = React.lazy(() => import("./Project/project"));
const RecurringList = React.lazy(() => import("./Project/RecurringList.jsx"));
const ManageProject = React.lazy(() => import("./Project/manag-project"));
const ProjectTickets = React.lazy(() => import("./Project/ProjectTickets"));
const ProjectTicket = React.lazy(() => import("./Project/ProjectTicket"));
const ProjectActivityAssg = React.lazy(() =>
  import("./Project/ProjectActivityAssg.jsx")
);
const ActivityLogDetail = React.lazy(() =>
  import("./Project/ActivityLogDetail.jsx")
);
const Reports = React.lazy(() => import("./Report/reports"));
const Tickets = React.lazy(() => import("./Tickets/tickets"));
const TicketAssign = React.lazy(() => import("./Tickets/ticketassign"));
const CreateTicket = React.lazy(() => import("./Tickets/create-ticket"));
const Subdepartments = React.lazy(() =>
  import("./Settings/Department/sub-department")
);
const Departments = React.lazy(() =>
  import("./Settings/Department/departments")
);
const Services = React.lazy(() => import("./Settings/Service/services"));
const Termconditions = React.lazy(() =>
  import("./Settings/Termcondition/termconditions")
);
const Banks = React.lazy(() => import("./Settings/Bank/banks"));
const ManageBank = React.lazy(() => import("./Settings/Bank/manage-bank"));

const TermconditionManage = React.lazy(() =>
  import("./Settings/Termcondition/manage-termcondition")
);
const ProjectCategories = React.lazy(() =>
  import("./Settings/ProjectCategory/projectCategories")
);
const Appointments = React.lazy(() => import("./Appointments/appointments"));
const TodayReport = React.lazy(() => import("./Dashboard/TodayReport"));
const ReviewAssignments = React.lazy(() =>
  import("./ReviewAssignments/ReviewAssignments")
);
const Profile = React.lazy(() => import("./Profile/Profile.jsx"));
const ManageProfile = React.lazy(() => import("./Profile/ManageProfile.jsx"));
const ChangePassEmail = React.lazy(() =>
  import("./Profile/ChangePassEmail.jsx")
);
const Bills = React.lazy(() => import("./Bills/Bills"));
const BillsReports = React.lazy(() => import("./Bills/BillsReports.jsx"));
const Bill = React.lazy(() => import("./Bills/Bill"));
const ManageBill = React.lazy(() => import("./Bills/ManageBill"));
const AudioRecorder = React.lazy(() => import("./audio/recorder"));
const ActiveEmployees = React.lazy(() => import("./Employee/activeEmployees"));
const Messages = React.lazy(() => import("./chat/messages"));
const EmpHistoryDash = React.lazy(() =>
  import("./EmpHistory/empHistoryDashboard")
);
const EmpWorkReport = React.lazy(() => import("./EmpHistory/EmpWorkReport"));
const EmpHistoryTickets = React.lazy(() =>
  import("./EmpHistory/EmpHistoryTickets")
);
const EmpHistoryActs = React.lazy(() => import("./EmpHistory/EmpHistoryActs"));
const EmpHistoryProjects = React.lazy(() =>
  import("./EmpHistory/empHistoryProjects")
);
const Branches = React.lazy(() =>
  import("./Settings/ProjectBranch/projectBranches")
);
const Expenses = React.lazy(() => import("./Expenses/Expenses"));
const ManageExpense = React.lazy(() => import("./Expenses/ManageExpense"));
const Expense = React.lazy(() => import("./Expenses/Expense"));
const OfficeExpenses = React.lazy(() =>
  import("./OfficeExpense/OfficeExpenses.jsx")
);
const ManageOfcExpense = React.lazy(() =>
  import("./OfficeExpense/ManageOfcExpense.jsx")
);
const OfficeExpense = React.lazy(() =>
  import("./OfficeExpense/OfficeExpense.jsx")
);
const ManageQuotations = React.lazy(() => import("./Quotations/stepForm.jsx"));
const Quotations = React.lazy(() => import("./Quotations/Quotations"));
const Quotation = React.lazy(() => import("./Quotations/quotation.jsx"));
const ExpenseTypes = React.lazy(() =>
  import("./Settings/expenseCategory/expenseTypes.jsx")
);

const ManageRActivity = React.lazy(() =>
  import("./recurringActivity/ManageRActivity.jsx")
);
const RecurringActivities = React.lazy(() =>
  import("./recurringActivity/recurringActivities.jsx")
);
const RecurringActivity = React.lazy(() =>
  import("./recurringActivity/RecurringActivity.jsx")
);
const ManageCompany = React.lazy(() => import("./Company/ManageCompany.jsx"));
const BillDetails = React.lazy(() => import("./Bills/BillDetails.js"));

const ManageProduct = React.lazy(() => import("./Product/ManageProduct.jsx"));
const Products = React.lazy(() => import("./Product/Products.jsx"));
const Product = React.lazy(() => import("./Product/Product.jsx"));
const Sales = React.lazy(() => import("./Product/Sales.jsx"));
const Purchases = React.lazy(() => import("./Product/Purchases.jsx"));
const ManagePurchase = React.lazy(() => import("./Product/managePurchase.jsx"));
const PurchaseReport = React.lazy(() => import("./Product/PurchaseReport.jsx"));
const SaleReport = React.lazy(() => import("./Product/SaleReport.jsx"));
const ManageSale = React.lazy(() => import("./Product/ManageSale.jsx"));
const ProformaInvoices = React.lazy(() =>
  import("./Product/ProformaInvoices.jsx")
);
const ManagePI = React.lazy(() => import("./Product/ManagePI.jsx"));
const ProformaInvoiceReport = React.lazy(() =>
  import("./Product/ProformaInvoiceReport.jsx")
);
const ProductDashboard = React.lazy(() =>
  import("./Product/ProductDashboard.jsx")
);
const Attendance = React.lazy(() => import("./HRMS/Attendance"));
const AttendanceOverview = React.lazy(() =>
  import("./HRMS/AttendanceOverview")
);
const AttendanceDetail = React.lazy(() =>
  import("./HRMS/AttendanceDetails.jsx")
);
const AdminLeaves = React.lazy(() => import("./HRMS/adminleaves.jsx"));
const DailyAttendanceReport = React.lazy(() =>
  import("./HRMS/DailyAttendanceReport.jsx")
);
const ManageSalary = React.lazy(() => import("./HRMS/ManageSalary"));
const DistributeSalary = React.lazy(() => import("./HRMS/DistributeSalary"));
const EmpSalaryDetail = React.lazy(() => import("./HRMS/EmpSalaryDetail.jsx"));

const Holidays = React.lazy(() => import("./Settings/Holidays/Holidays"));
const ManageHolidays = React.lazy(() =>
  import("./Settings/Holidays/ManageHolidays")
);
const Leaves = React.lazy(() => import("./Settings/Leaves/Leaves"));
const ManageLeaves = React.lazy(() => import("./Settings/Leaves/AddLeavePopupForm.jsx"));

const ManageLead = React.lazy(() => import("./Lead/ManageLead.jsx"));
const ImportLeads = React.lazy(() => import("./Lead/ImportLeads.jsx"));
const Leads = React.lazy(() => import("./Lead/Leads.jsx"));
const LeadTodayActivities = React.lazy(() =>
  import("./Lead/LeadTodayActivities.jsx")
);
const Lead = React.lazy(() => import("./Lead/Lead.jsx"));
const LeadLOgs = React.lazy(() => import("./Lead/Log.jsx"));
const ToDos = React.lazy(() => import("./ToDo/ToDos"));

const Allowances = React.lazy(() => import("./Allowance/Allowances.jsx"));
const AllowanceDetail = React.lazy(() =>
  import("./Allowance/AllownaceDetail.jsx")
);

const Preferences = React.lazy(() => import("./Preferences/Preferences"));

const Queries = React.lazy(() => import("./Support/Queries"));
const ManageQuery = React.lazy(() => import("./Support/ManageQuery"));
const Query = React.lazy(() => import("./Support/Query"));

// const DashboardHeader = React.lazy(() => import('../Components/Header'));

const Error404 = React.lazy(() => import("./../Components/Error404"));
const AdminRoutes = [
  {
    path: "admin",
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "product/dashboard",
        element: <ProductDashboard />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "profile/edit",
        element: <ManageProfile />,
      },
      {
        path: "change-password/email",
        element: <ChangePassEmail />,
      },
      {
        path: "today/emp-report",
        element: <TodayReport />,
      },
      {
        path: "review/assg",
        element: <ReviewAssignments />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "project/:projectID",
        element: <Project />,
      },
      {
        path: "project/:projectID/tickets",
        element: <ProjectTickets />,
      },
      {
        path: "project/:projectID/assignment/:assignmentID",
        element: <ProjectActivityAssg />,
      },
      {
        path: "project/:projectID/assignment/:assignmentID/log/:logID",
        element: <ActivityLogDetail />,
      },
      {
        path: "project/:projectID/ticket/:ticketID",
        element: <ProjectTicket />,
      },
      {
        path: "create/project",
        element: <ManageProject />,
      },
      {
        path: "edit/project/:projectID",
        element: <ManageProject />,
      },
      {
        path: "ledger/project/:projectID",
        element: <Ledger />,
      },
      {
        path: "recurring/project/activities",
        element: <RecurringList />,
      },
      {
        path: "employees",
        element: <Employees />,
      },

      
      {
        path: "create/employee",
        element: <ManageEmployee />,
      },
      {
        path: "edit/employee/:userID",
        element: <ManageEmployee />,
      },

      {
        path: "employee/:employeeID",
        element: <Employeedetails/>,
      },

      {
        path: "attendance",
        element: <Attendance />,
      },
      {
        path: "attendance/overview",
        element: <AttendanceOverview />,
      },
      {
        path: "attendancedetail/:employeeID",
        element: <AttendanceDetail />,
      },
      {
        path: "attendance/dailyreport",
        element: <DailyAttendanceReport />,
      },
      {
        path: "leave",
        element: <AdminLeaves />,
      },
      {
        path: "salary/manage",
        element: <ManageSalary />,
      },
      {
        path: "salary/distribute",
        element: <DistributeSalary />,
      },
      {
        path: "salary/detail/:empSalaryID",
        element: <EmpSalaryDetail />,
      },

      {
        path: "hrms/employee/docs",
        element: <EmployeeDoc />,
      },
      {
        path: "users/active",
        element: <ActiveEmployees />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "create/client",
        element: <ManageClient />,
      },
      {
        path: "edit/client/:userID",
        element: <ManageClient />,
      },
      {
        path: "vendors",
        element: <Vendors />,
      },
      {
        path: "create/vendor",
        element: <ManageVendor />,
      },
      {
        path: "edit/vendor/:userID",
        element: <ManageVendor />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "tickets",
        element: <Tickets />,
      },
      {
        path: "ticket/assign/:ticketID",
        element: <TicketAssign />,
      },
      {
        path: "ticket/create",
        element: <CreateTicket />,
      },
      {
        path: "create/recurring-activity",
        element: <ManageRActivity />,
      },
      {
        path: "edit/recurring-activity/:recActID",
        element: <ManageRActivity />,
      },
      {
        path: "recurrings",
        element: <RecurringActivities />,
      },
      {
        path: "recurring/:recActID",
        element: <RecurringActivity />,
      },
      {
        path: "settings/department/:departmentID",
        element: <Subdepartments />,
      },
      {
        path: "settings/departments",
        element: <Departments />,
      },
      {
        path: "settings/holidays",
        element: <Holidays />,
      },
      {
        path: "settings/holidays/add",
        element: <ManageHolidays />,
      },
      {
        path: "settings/leaves",
        element: <Leaves />,
      },
      // {
      //   path: "settings/leaves/add",
      //   element: <ManageLeaves />,
      // },
      {
        path: "settings/services",
        element: <Services />,
      },
      {
        path: "settings/termConditions",
        element: <Termconditions />,
      },
      {
        path: "settings/termCondition/create",
        element: <TermconditionManage />,
      },
      {
        path: "settings/banks",
        element: <Banks />,
      },
      {
        path: "settings/bank/create",
        element: <ManageBank />,
      },
      {
        path: "settings/project-cat",
        element: <ProjectCategories />,
      },
      {
        path: "settings/office-expense",
        element: <ExpenseTypes />,
      },

      {
        path: "settings/branches",
        element: <Branches />,
      },
      {
        path: "settings/education",
        element: <Educations />,
      },
      {
        path: "settings/designation",
        element: <Designations />,
      },
      {
        path: "settings/documents",
        element: <Documents/>,
      },

      {
        path: "appointments",
        element: <Appointments />,
      },
      {
        path: "bills",
        element: <Bills />,
      },
      {
        path: "bills/reports",
        element: <BillsReports />,
      },
      {
        path: "invoice/:id",
        element: <BillDetails />,
      },
      {
        path: "create/bill",
        element: <ManageBill />,
      },
      {
        path: "bill/:billID",
        element: <Bill />,
      },
      {
        path: "expenses",
        element: <Expenses />,
      },
      {
        path: "expense/:expenseID",
        element: <Expense />,
      },
      {
        path: "create/expense",
        element: <ManageExpense />,
      },
      {
        path: "edit/expense/:expenseID",
        element: <ManageExpense />,
      },
      {
        path: "ofc-expenses",
        element: <OfficeExpenses />,
      },
      {
        path: "ofc-expense/:ofcExpenseID",
        element: <OfficeExpense />,
      },
      {
        path: "create/ofc-expense",
        element: <ManageOfcExpense />,
      },

      // {
      //     path: 'addallowance',
      //     element: <AddAllowance/>
      // },
      // {
      //     path: 'allowancedetails',
      //     element: <AllowanceDetails/>
      // },

      // {
      //     path: 'allowancelist',
      //     element: <AllowanceList/>
      // },

      {
        path: "edit/ofc-expense/:ofcExpenseID",
        element: <ManageOfcExpense />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
      {
        path: "create/audio",
        element: <AudioRecorder />,
      },
      {
        path: "history/emp/:empID/dashboard",
        element: <EmpHistoryDash />,
      },
      {
        path: "history/emp/:empID/work-report",
        element: <EmpWorkReport />,
      },
      {
        path: "history/emp/:empID/tickets",
        element: <EmpHistoryTickets />,
      },
      {
        path: "history/emp/:empID/activities",
        element: <EmpHistoryActs />,
      },
      {
        path: "history/emp/:empID/projects",
        element: <EmpHistoryProjects />,
      },
      {
        path: "quotations",
        element: <Quotations />,
      },
      {
        path: "quotation/:quotationID",
        element: <Quotation />,
      },
      {
        path: "create/quotation",
        element: <ManageQuotations />,
      },
      {
        path: "company/add",
        element: <ManageCompany />,
      },
      {
        path: "company/edit/:companyID",
        element: <ManageCompany />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        path: "product/:productID",
        element: <Product />,
      },
      {
        path: "create/product",
        element: <ManageProduct />,
      },
      {
        path: "purchases",
        element: <Purchases />,
      },
      {
        path: "purchase/report/:purchaseID",
        element: <PurchaseReport />,
      },
      {
        path: "create/purchase",
        element: <ManagePurchase />,
      },
      {
        path: "sales",
        element: <Sales />,
      },
      {
        path: "sale/report/:saleID",
        element: <SaleReport />,
      },
      {
        path: "create/sale",
        element: <ManageSale />,
      },
      {
        path: "pis",
        element: <ProformaInvoices />,
      },
      {
        path: "pi/report/:PiID",
        element: <ProformaInvoiceReport />,
      },
      {
        path: "create/pi",
        element: <ManagePI />,
      },
      {
        path: "create/lead",
        element: <ManageLead />,
      },
      {
        path: "import/lead",
        element: <ImportLeads />,
      },
      {
        path: "edit/lead/:leadID",
        element: <ManageLead />,
      },
      {
        path: "leads",
        element: <Leads />,
      },
      {
        path: "lead/logs/:leadID",
        element: <LeadLOgs />,
      },
      {
        path: "lead/:leadID",
        element: <Lead />,
      },
      {
        path: "lead/today/activities",
        element: <LeadTodayActivities />,
      },
      {
        path: "to-dos",
        element: <ToDos />,
      },
      {
        path: "preferences",
        element: <Preferences />,
      },
      {
        path: "support/queries",
        element: <Queries />,
      },
      {
        path: "support/query/add",
        element: <ManageQuery />,
      },
      {
        path: "allowance",
        element: <Allowances />,
      },
      {
        path: "allowance/:allowanceID",
        element: <AllowanceDetail />,
      },
      {
        path: "support/query/:supportQueryID",
        element: <Query />,
      },
    ],
  },
];
export default AdminRoutes;
