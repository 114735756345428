import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";

export default function Demosuccess() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {" "}
      <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
      <link
        rel="stylesheet"
        type="text/css"
        href="/main/css/responsive.css"
      ></link>
      <div className="main-page-wrapper font-rubik">
        <Header />

        <div class="hero-banner-two bg-gray">
          <div class="container">
            <div class="row align-items-start justify-content-between">
              <div
                class=" col-lg-8 offset-lg-2   aos-init aos-animate"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div class="success-box md-mt-50">
                  <img
                    src="/main/images/success.png"
                    className="img-success"
                    alt=""
                  />

                  <h1 className="maintitles">
                    Demo Request Submitted Successfully!
                  </h1>
                  <p className="mainp">
                    Thank you for your interest in our project management
                    solutions! We are pleased to inform you that your demo
                    request has been submitted successfully.
                  </p>
                  <p className="mainp">
                    Our team will review your request and get back to you
                    shortly to schedule a convenient time for your demo. We look
                    forward to showing you how our tools can enhance your
                    project management experience.
                  </p>
                  <p className="mainp">
                    If you have any questions in the meantime, please feel free
                    to reach out to us at support@taskflier.com.
                  </p>

                  <Link class="theme-btn-2 mt-10 md-mt-30" to="/">
                    Back To Home <i class="bi bi-arrow-right pl-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bg-wrapper bg-white">
          <div className="bubble-one" />
          <div className="bubble-two" />
          <div className="bubble-three" />
          <div className="fancy-short-banner-two">
            <div className="container">
              <div className="content-wrapper">
                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                  <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                  <Link
                    to="https://mnbproject.com/#"
                    data-toggle="modal"
                    data-target="#contactModal"
                  >
                    Request Demo
                  </Link>
                  <div className="bubble-one" />
                  <div className="bubble-two" />
                  <div className="bubble-three" />
                  <div className="bubble-four" />
                  <div className="bubble-five" />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>

        <button className="scroll-top" style={{ display: "none" }}>
          <i className="fa fa-angle-up" aria-hidden="true" />
        </button>
      </div>
    </>
  );
}


