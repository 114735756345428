import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import {
  dateOption,
  fetchComponentPrefrences,
  getMenuName,
  getProdDevURL,
  getSubMenuName,
} from "../../helper";
import { axiosInstance, dateTimeOptions } from "../../helper";
import { useContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { AppContext } from "../../context/context";

const Header = () => {
  const navigate = useNavigate();

  const appContext = useContext(AppContext);
  const { setProfile, profile } = appContext;

  const userName = localStorage.getItem("user-name");
  const http = document.location.pathname.toLowerCase().split("/");
  // console.log("http is: ", http);
  const token = localStorage.getItem("token");
  const decoded = token ? jwt_decode(token) : navigate("/");

  const [isLoading, setIsLoading] = useState(true);
  const [isPreferencesLoading, setIsPreferencesLoading] = useState(true);

  const [alert, setAlert] = useState({});
  const [profileData, setPorifileData] = useState({});
  const [permissionData, setPermissionData] = useState([]);
  const [recurringsData, setRecurringsData] = useState([]); // holds the recurring activities of projects
  const [preferences, setPreferences] = useState([]);
  const [leadActivities, setLeadActivities] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  // Logout
  const LogOut = async () => {
    try {
      const res = await axiosInstance.get("/auth/logout", {
        params: {
          userID: decoded.id,
        },
      });

      console.log(res);

      if (res.data.status === "success") {
        localStorage.clear();
        window.location.href = "/";
        // navigate("/")
      } else if (res.data.status === "error") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Server Error");
    }
  };

  // Fetch Alert
  const fetchAlert = async () => {
    let url = "/admin/alert";
    if (decoded.role === 2) {
      url = "/emp/alert";
    } else if (decoded.role === 3) {
      url = "/client/alert";
    } else if (decoded.role === 0) {
      url = "/supAdmin/alert";
    }
    try {
      const res = await axiosInstance.get(url);
      console.log("alert: ", res);
      if (res.data.status === "success") {
        setAlert(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch Profile
  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get("/auth/profile");
      console.log("profile: ", res);
      if (res.data.status === "success") {
        setPorifileData(res.data.data);
        setProfile(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Permission for sidePane
  const fetchPermission = async () => {
    let url = "/admin/permission";
    if (decoded.role === 2) {
      url = "/emp/permission";
    } else if (decoded.role === 3) {
      url = "/client/permission";
    } else if (decoded.role === 0) {
      url = "/supAdmin/permission";
    }
    setIsLoading(true);
    try {
      const res = await axiosInstance.get(url);
      console.log("Permission : ", res.data.data);
      if (res.data.status === "success") {
        setPermissionData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Preferences/Themes
  const fetchPreferences = async () => {
    let url = "/admin/preferences";
    if (decoded.role === 2) {
      url = "/emp/preferences";
    } else if (decoded.role === 3) {
      url = "/client/preferences";
    } else if (decoded.role === 0) {
      url = "/supAdmin/preferences";
    }
    setIsPreferencesLoading(true);
    try {
      const res = await axiosInstance.get(url);
      console.log("Preferences : ", res.data.data);
      if (res.data.status === "success") {
        setPreferences(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsPreferencesLoading(false);
    }
  };

  // Fetch Project Recurring Activities
  const fetchRecurrings = async () => {
    let url = "/admin/project/recurrings";

    if (decoded.role === 2) {
      url = "/emp/project/recurrings";
    } else if (decoded.role === 3) {
      url = "/client/project/recurrings";
    } else if (decoded.role === 0) {
      url = "/supAdmin/project/recurrings";
    }

    setIsLoading(true);

    try {
      const res = await axiosInstance.get(url, {
        params: {
          freq: "monthly",
        },
      });
      console.log("Recurrings : ", res.data.data);
      if (res.data.status === "success") {
        setRecurringsData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Lead Today Activities
  const fetchLeadActivities = async () => {
    let url = "/admin/lead/today/activities";

    if (decoded.role === 2) {
      url = "/emp/lead/today/activities";
    } else if (decoded.role === 3) {
      url = "/client/lead/today/activities";
    } else if (decoded.role === 0) {
      url = "/supAdmin/lead/today/activities";
    }

    setIsLoading(true);

    try {
      const res = await axiosInstance.get(url, {
        params: {
          freq: "monthly",
        },
      });
      console.log("Lead Activities : ", res.data.data);
      if (res.data.status === "success") {
        setLeadActivities(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const getMenuName = (menuName)=> {
  //   if(menuName === "Projects"){
  //     return profile?.companyData?.customProjectName
  //   }

  //   return menuName
  // }

  // const getSubMenuName = (subMenuName)=> {
  //   const arr = subMenuName.split(" ");
  //   // ["add", "projects"]

  //   if(arr[1] === "Project"){

  //     arr[1] = profile?.companyData?.customProjectName;
  //     const word = arr.join(" ");
  //     return word

  //   }else if(arr[1] === "Projects"){

  //     arr[1] = `${profile?.companyData?.customProjectName}s`;
  //     const word = arr.join(" ");
  //     return word
  //   }

  //   return  subMenuName

  // }

  useEffect(() => {
    fetchAlert();
    fetchProfile();
    fetchPermission();
    fetchRecurrings();
    fetchPreferences();
    fetchLeadActivities();
  }, []);

  const endPoint = http[1];
  return (
    <>
      <div className="navbar-custom">
        <div className="topbar container-fluid">
          <div className="d-flex align-items-center gap-lg-2 gap-1">
            <a
              className="button-toggle-menu d-lg-none d-md-none "
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              role="button"
              aria-controls="offcanvasExample"
            >
              <i class="bi bi-list"></i>
            </a>

            <div className="app-search dropdown d-lg-none d-md-none  ">
              {isLoading ? (
                <p>Loading .....</p>
              ) : profileData.companyData?.logoUri ? (
                <Link to={`${http[1]}/dashboard`} className="logo logo-dark">
                  <span className="logo-lg">
                    <img
                      src={`${getProdDevURL().REACT_APP_SERVER_PUBLIC_URL}/${
                        profileData.companyData.logoUri
                      }`}
                      alt="dark logo"
                    />
                  </span>
                  <span className="logo-sm">
                    <img
                      src={`${getProdDevURL().REACT_APP_SERVER_PUBLIC_URL}/${
                        profileData.companyData.logoUri
                      }`}
                      alt="small logo"
                    />
                  </span>
                </Link>
              ) : (
                <Link to={`${http[1]}/dashboard`} className="logo logo-dark">
                  <span className="logo-lg">
                    <img
                      src={`${
                        getProdDevURL().REACT_APP_BASE_URL
                      }/files/img/static/logo.png`}
                      alt="dark logo"
                    />
                  </span>
                  <span className="logo-sm">
                    <img
                      src={`${
                        getProdDevURL().REACT_APP_BASE_URL
                      }/files/img/static/logo.png`}
                      alt="small logo"
                    />
                  </span>
                </Link>
              )}
            </div>
            <span class="d-lg-flex d-xs-none flex-column gap-1">
              <h4 class="my-0 text-dark">
                Hello{" "}
                {endPoint && endPoint === "admin"
                  ? profileData?.firstName
                  : endPoint && endPoint === "employee"
                  ? profileData?.firstName
                  : endPoint && endPoint === "user"
                  ? profileData?.firstName
                  : endPoint && endPoint === "supadmin"
                  ? profileData?.firstName
                  : ""}
              </h4>
              <h5 class="my-0 fw-normal text-primary">
                {currentDate.toLocaleString("en-IN", {
                  ...dateOption,
                  weekday: "short",
                })}
              </h5>
            </span>
          </div>

          <ul className="topbar-menu d-flex align-items-center gap-2">
            <li className="top-icon hidden-xs">
              <Link to={`${http[1]}/to-dos`}>
                <span class="" title={"To-Do"}>
                  <i class="bi bi-card-checklist"></i>
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"></span>
                </span>
              </Link>
            </li>

            <li
              className="top-icon hidden-xs"
              data-bs-toggle="offcanvas"
              data-bs-target="#themesetting"
              aria-controls="offcanvasRight"
            >
              <span class="">
                <i class="bi bi-gear text-primary"></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"></span>
              </span>
            </li>

            {http[1] === "admin" && recurringsData ? (
              <li className="top-icon hidden-xs">
                <Link to={`admin/recurring/project/activities`}>
                  <span class="" title={"Recurring Tasks"}>
                    <i class="bi bi-arrow-repeat"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                      {recurringsData.length > 0 ? recurringsData.length : ""}
                    </span>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {http[1] === "admin" && leadActivities ? (
              <li className="top-icon hidden-xs">
                <Link to={`admin/lead/today/activities`}>
                  <span class="" title={"Lead Activities"}>
                    <i class="bi bi-activity"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                      {leadActivities.length > 0 ? leadActivities.length : ""}
                    </span>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {alert.newAppointments && alert.newAppointments.value > 0 ? (
              <li className="top-icon hidden-xs">
                <Link
                  to={alert.newAppointments ? alert.newAppointments.link : ""}
                >
                  <span
                    class=""
                    title={alert.newAppointments.description ?? ""}
                  >
                    <i class="bi bi-calendar-check-fill"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                      {alert.newAppointments ? alert.newAppointments.value : ""}
                    </span>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {alert.delayedProjects && alert.delayedProjects.value > 0 ? (
              <li className="top-icon hidden-xs">
                <Link
                  to={alert.delayedProjects ? alert.delayedProjects.link : ""}
                >
                  <span title={alert.delayedProjects.description ?? ""}>
                    <i class="bi bi-file-bar-graph-fill"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {alert.delayedProjects ? alert.delayedProjects.value : ""}
                    </span>
                  </span>
                </Link>
              </li>
            ) : null}
            {alert.newTickets && alert.newTickets.value > 0 ? (
              <li className="top-icon hidden-xs">
                <Link to={alert.newTickets ? alert.newTickets.link : ""}>
                  <span title={alert.newTickets.description ?? ""}>
                    <i class="bi bi-ticket-fill"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                      {alert.newTickets ? alert.newTickets.value : ""}
                    </span>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {alert.reviewTickets && alert.reviewTickets.value > 0 ? (
              <li className="top-icon">
                <Link to={alert.reviewTickets ? alert.reviewTickets.link : ""}>
                  <span title={alert.reviewTickets.description ?? ""}>
                    <i class="bi bi-ticket-fill"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                      {alert.reviewTickets ? alert.reviewTickets.value : ""}
                    </span>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {endPoint && endPoint === "admin" ? (
              alert.activeEmployees ? (
                <li className="top-icon">
                  <Link to={"/admin/messages"} title="Active Employees">
                    {/* <i class="bi bi-person-fill-check"></i> */}
                    <i class="bi bi-chat-dots-fill"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                      {alert.activeEmployees ? alert.activeEmployees.value : ""}
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )
            ) : endPoint && endPoint === "employee" ? (
              <Link
                to={"/employee/messages"}
                type="button"
                class="btn btn-primary btn-sm rounded-pill position-relative"
                title="Active Employees"
              >
                <i class="bi bi-chat-dots-fill"></i>
              </Link>
            ) : endPoint && endPoint === "user" ? (
              <Link
                to={"/user/messages"}
                type="button"
                class="btn btn-primary btn-sm rounded-pill position-relative"
                title="Active Employees"
              >
                <i class="bi bi-chat-dots-fill"></i>
              </Link>
            ) : (
              ""
            )}

            <li className="dropdown">
              <Link
                className="nav-link dropdown-toggle arrow-none px-2"
                data-bs-toggle="dropdown"
                to="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span className="account-user-avatar">
                  {isLoading ? (
                    "..."
                  ) : profileData?.profileImgUri ? (
                    <img
                      // src={profileData.profileImgUri}
                      src={`${getProdDevURL().REACT_APP_SERVER_PUBLIC_URL}/${
                        profileData.profileImgUri
                      }`}
                      alt="user-image1"
                      style={{ width: "32px", height: "32px" }}
                      className="rounded-circle img-fluid"
                    />
                  ) : (
                    <img
                      src={`${
                        getProdDevURL().REACT_APP_BASE_URL
                      }/files/maleUserAvatar.webp`}
                      alt="user-image1"
                      style={{ width: "32px", height: "32px" }}
                      className="rounded-circle img-fluid"
                    />
                  )}
                </span>
                &nbsp;
                <span className="d-lg-flex flex-column gap-1 d-none">
                  <h5 className="my-0">
                    {endPoint && endPoint === "admin"
                      ? profileData?.firstName
                      : endPoint && endPoint === "employee"
                      ? profileData?.firstName
                      : endPoint && endPoint === "user"
                      ? profileData?.firstName
                      : endPoint && endPoint === "supadmin"
                      ? profileData?.firstName
                      : ""}
                  </h5>
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                <div className=" dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Welcome</h6>
                </div>

                {endPoint && endPoint === "admin" ? (
                  <>
                    <Link to="/admin/profile" className="dropdown-item">
                      <i class="bi bi-person"></i> <span> My Account</span>
                    </Link>
                    <Link to="/admin/preferences" className="dropdown-item">
                      <i class="bi bi-palette"></i> <span>Preferences</span>
                    </Link>
                  </>
                ) : endPoint && endPoint === "employee" ? (
                  <>
                    <Link to="/employee/profile" className="dropdown-item">
                      <i class="bi bi-person"></i> <span> My Account</span>
                    </Link>
                  </>
                ) : endPoint && endPoint === "user" ? (
                  <>
                    <Link to="/user/profile" className="dropdown-item">
                      <i class="bi bi-person"></i> <span> My Account</span>
                    </Link>
                  </>
                ) : (
                  ""
                )}

                <Link className="dropdown-item" onClick={LogOut}>
                  <i class="bi bi-box-arrow-right"></i> <span> Logout</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="offcanvas offcanvas-start navbar-dark bg-dark text-white"
        data-bs-backdrop="static"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          {isLoading ? (
            <p>Loading .....</p>
          ) : profileData.companyData?.logoUri ? (
            <Link to={`${http[1]}/dashboard`} className="logo logo-dark">
              <span className="logo-lg">
                <img
                  src={`${getProdDevURL().REACT_APP_SERVER_PUBLIC_URL}/${
                    profileData.companyData.logoUri
                  }`}
                  alt="dark logo"
                />
              </span>
              <span className="logo-sm">
                <img
                  src={`${getProdDevURL().REACT_APP_SERVER_PUBLIC_URL}/${
                    profileData.companyData.logoUri
                  }`}
                  alt="small logo"
                />
              </span>
            </Link>
          ) : (
            <Link to={`${http[1]}/dashboard`} className="logo logo-dark">
              <span className="logo-lg">
                <img
                  src={`${
                    getProdDevURL().REACT_APP_BASE_URL
                  }/files/img/static/logo.png`}
                  alt="dark logo"
                />
              </span>
              <span className="logo-sm">
                <img
                  src={`${
                    getProdDevURL().REACT_APP_BASE_URL
                  }/files/img/static/logo.png`}
                  alt="small logo"
                />
              </span>
            </Link>
          )}
          <button
            type="button"
            class="btn btn-sm btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"></i>
          </button>
        </div>
        <div class="offcanvas-body pl-0 pt-0">
          <div
            className="h-100"
            id="leftside-menu-container"
            data-simplebar="init"
            style={{ overflow: "scroll" }}
          >
             {endPoint && endPoint === "admin" ? (
            <ul className="side-nav mt-1" id="menu">
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                permissionData.map((perm) => {
                  return (
                    <li className="side-nav-item">
                      {perm.menuID.subMenuData.length > 0 ? (
                        <Link
                          data-bs-toggle="collapse"
                          to={`#${perm._id}`}
                          aria-expanded="false"
                          aria-controls={perm._id}
                          className="side-nav-link"
                        >
                          <i class={perm.menuID.iconClass}></i>
                          <span>
                            {profile.companyData?.customProjectName
                              ? getMenuName(
                                  perm.menuTitle,
                                  profile.companyData?.customProjectName
                                )
                              : perm.menuTitle}
                          </span>
                          <span className="menu-arrow"></span>
                        </Link>
                      ) : (
                        <li className="side-nav-item" >
                          <Link
                            to={`/admin${perm.menuID.url}`}
                            className="side-nav-link"
                          >
                            <i class={perm.menuID.iconClass}></i>
                            <span>
                              {profile.companyData?.customProjectName
                                ? getMenuName(
                                    perm.menuTitle,
                                    profile.companyData?.customProjectName
                                  )
                                : perm.menuTitle}
                            </span>
                          </Link>
                        </li>
                      )}
                      {perm.menuID.subMenuData.length > 0 ? (
                        <div
                          className="collapse"
                          id={perm._id}
                          data-bs-parent="#menu"
                        >
                          <ul className="side-nav-second-level">
                            {perm.menuID.subMenuData.map((subMenu) => {
                              return (
                                <li data-bs-dismiss="offcanvas">
                                  <Link to={`/admin${subMenu.url}`}>
                                    {profile.companyData?.customProjectName
                                      ? getSubMenuName(
                                          subMenu.name,
                                          profile.companyData?.customProjectName
                                        )
                                      : subMenu.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}
                    </li>
                  );
                })
              )}
            </ul>
          ) : endPoint && endPoint === "employee" ? (
            <ul className="side-nav" id="employeemob">
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link
                  to="/employee/dashboard"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i class="bi bi-grid-3x3-gap"></i>
                  <span> Dashboard </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarProjects"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-box-seam"></i>
                  <span>
                    {profile?.companyData?.customProjectName
                      ? getSubMenuName(
                          "My Projects",
                          profile?.companyData?.customProjectName
                        )
                      : "My Projects"}
                  </span>
                  <span className="menu-arrow"></span>
                </Link>

                <div className="collapse px-2" id="sidebarProjects">
                  <ul className="side-nav-second-level">
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/projects`}>
                        {profile?.companyData?.customProjectName
                          ? getSubMenuName(
                              "All Projects",
                              profile?.companyData?.customProjectName
                            )
                          : "All Projects"}
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/employee/projects?status=0">
                        {profile?.companyData?.customProjectName
                          ? getSubMenuName(
                              "Pending Projects",
                              profile?.companyData?.customProjectName
                            )
                          : "Pending Projects"}
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/employee/projects?status=3">
                        {profile?.companyData?.customProjectName
                          ? getSubMenuName(
                              "Completed Projects",
                              profile?.companyData?.customProjectName
                            )
                          : "Completed Projects"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link to="/employee/reports" className="side-nav-link">
                  <i class="bi bi-repeat"></i>
                  <span> Work Report </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarTickets"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-ticket-perforated"></i>
                  <span> Tickets </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarTickets">
                  <ul className="side-nav-second-level">
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/create/ticket`}>Create Ticket</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/tickets`}>All Tickets</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/employee/tickets?status=0">New Tickets</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/employee/tickets?status=3">In Progress</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/employee/tickets?status=2">Under Review</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/employee/tickets?status=1">Completed</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="side-nav-item" >
                <Link
                  data-bs-toggle="collapse"
                  to="#EmpAttendance"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-diagram-3"></i>
                  <span>HRMS </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="EmpAttendance">
                  <ul className="side-nav-second-level">
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/Emp_Attendance`}>Attendance</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/EmpLeaves`}>Leaves</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/UploadDoc`}>Upload Document</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="side-nav-item" >
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarLeads"
                  aria-expanded="false"
                  aria-controls="sidebarLeads"
                  className="side-nav-link"
                >
                  <i class="bi bi-node-plus"></i>
                  <span> Leads </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarLeads">
                  <ul className="side-nav-second-level">
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/create/lead`}>Create Lead</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas"> 
                      <Link to={`/employee/leads`}>All Leads</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/leads?status=0`}>New Leads</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/leads?status=1`}>
                        Converted Leads
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/leads?status=2`}>
                        Contacted Leads
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/leads?status=3`}>Lost Leads</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/leads?status=4`}>Hold Leads</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link
                  to="/employee/to-dos"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i class="bi bi-card-checklist"></i>
                  <span> To Do </span>
                </Link>
              </li>

              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link
                  to="/employee/expense"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i class="bi bi-card-checklist"></i>
                  <span> Expense </span>
                </Link>
              </li>

              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarSupport"
                  aria-expanded="false"
                  aria-controls="sidebarSupport"
                  className="side-nav-link"
                >
                  <i class="bi bi-person-workspace"></i>
                  <span>Support </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarSupport">
                  <ul className="side-nav-second-level">
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/support/query/add`}>Add Query</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/employee/support/queries`}>Queries</Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          ) : endPoint && endPoint === "user" ? (
            <ul className="side-nav">
              <li className="side-nav-item" data-bs-dismiss="offcanvas" >
                <NavLink to="/user/dashboard" className="side-nav-link">
                  <i class="bi bi-grid-3x3-gap"></i>
                  <span> Dashboard </span>
                </NavLink>
              </li>
              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarProjects"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-box-seam"></i>
                  <span>
                    {profile.companyData?.customProjectName
                      ? getSubMenuName(
                          "My Projects",
                          profile?.companyData?.customProjectName
                        )
                      : "My Projects"}
                  </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarProjects">
                  <ul className="side-nav-second-level">
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/user/projects`}>
                        {profile.companyData?.customProjectName
                          ? getSubMenuName(
                              "All Projects",
                              profile?.companyData?.customProjectName
                            )
                          : "All Projects"}
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas"> 
                      <Link to="/user/projects?status=0">Pending Projects</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/user/projects?status=3">
                        Completed Projects
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarTickets"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-ticket-perforated"></i>
                  <span> Tickets </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarTickets">
                  <ul className="side-nav-second-level">
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/user/new-ticket">Create New Ticket</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to={`/user/tickets`}>All Tickets</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/user/tickets?status=0">Pending Tickets</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/user/tickets?status=4">Review Tickets</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link to="/user/tickets?status=3">Completed Tickets</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link
                  to="/user/appointments"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i class="bi bi-calendar3"></i>
                  <span> Appointments </span>
                </Link>
              </li>
              <li className="side-nav-item" >
                <Link className="side-nav-link" onClick={LogOut}>
                  <i class="bi bi-box-arrow-right"></i>
                  <span> Logout</span>
                </Link>
              </li>
            </ul>
          ) : endPoint && endPoint === "supadmin" ? (
            <ul className="side-nav mt-1">
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link to="/supadmin/dashboard" className="side-nav-link">
                  <i class="bi bi-grid-3x3-gap"></i>
                  <span> Dashboard </span>
                </Link>
              </li>
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link to="/supadmin/admins" className="side-nav-link">
                  <i class="bi bi-card-checklist"></i>
                  <span> Admins </span>
                </Link>
              </li>
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link to="/supadmin/menu" className="side-nav-link">
                  <i class="bi bi-menu-button-fill"></i>
                  <span> Menu </span>
                </Link>
              </li>
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link to="/supadmin/enquiries" className="side-nav-link">
                  <i class="bi bi-person-lines-fill"></i>
                  <span> Enquiries </span>
                </Link>
              </li>
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link to="/supadmin/plan/taken/users" className="side-nav-link">
                  <i class="bi bi-person-check"></i>
                  <span> Subscribed Users </span>
                </Link>
              </li>
              <li className="side-nav-item" data-bs-dismiss="offcanvas">
                <Link to="/supadmin/support/queries" className="side-nav-link">
                  <i class="bi bi-person-workspace"></i>
                  <span> Support </span>
                </Link>
              </li>

              {/* <li className="side-nav-item">
                <Link to="/supadmin/sectors" className="side-nav-link">
                    <i class="bi bi-card-checklist"></i>
                    <span> Sectors </span>
                </Link>
              </li> */}
            </ul>
          ) : (
            <></>
          )}
            <div className="clearfix"></div>
          </div>

         
        </div>
      </div>

      {/* For Large Screens */}
      <div className="leftside-menu" id="leftside-menu">
        {isLoading ? (
          <p>Loading .....</p>
        ) : profileData.companyData?.logoUri ? (
          <Link to={`${http[1]}/dashboard`} className="logo logo-dark">
            <span className="logo-lg">
              <img
                src={`${getProdDevURL().REACT_APP_SERVER_PUBLIC_URL}/${
                  profileData.companyData.logoUri
                }`}
                alt="dark logo"
              />
            </span>
            <span className="logo-sm">
              <img
                src={`${getProdDevURL().REACT_APP_SERVER_PUBLIC_URL}/${
                  profileData.companyData.logoUri
                }`}
                alt="small logo"
              />
            </span>
          </Link>
        ) : (
          <Link to={`${http[1]}/dashboard`} className="logo logo-dark">
            <span className="logo-lg">
              <img
                src={`${
                  getProdDevURL().REACT_APP_BASE_URL
                }/files/img/static/logo.png`}
                alt="dark logo"
              />
            </span>
            <span className="logo-sm">
              <img
                src={`${
                  getProdDevURL().REACT_APP_BASE_URL
                }/files/img/static/logo.png`}
                alt="small logo"
              />
            </span>
          </Link>
        )}
        <div
          className="h-100"
          id="leftside-menu-container"
          data-simplebar="init"
          style={{ overflow: "scroll" }}
        >
          {endPoint && endPoint === "admin" ? (
            <ul className="side-nav mt-1" id="menu">
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                permissionData.map((perm) => {
                  return (
                    <li className="side-nav-item">
                      {perm.menuID.subMenuData.length > 0 ? (
                        <Link
                          data-bs-toggle="collapse"
                          to={`#${perm._id}`}
                          aria-expanded="false"
                          aria-controls={perm._id}
                          className="side-nav-link"
                        >
                          <i class={perm.menuID.iconClass}></i>
                          <span>
                            {profile.companyData?.customProjectName
                              ? getMenuName(
                                  perm.menuTitle,
                                  profile.companyData?.customProjectName
                                )
                              : perm.menuTitle}
                          </span>
                          <span className="menu-arrow"></span>
                        </Link>
                      ) : (
                        <li className="side-nav-item">
                          <Link
                            to={`/admin${perm.menuID.url}`}
                            className="side-nav-link"
                          >
                            <i class={perm.menuID.iconClass}></i>
                            <span>
                              {profile.companyData?.customProjectName
                                ? getMenuName(
                                    perm.menuTitle,
                                    profile.companyData?.customProjectName
                                  )
                                : perm.menuTitle}
                            </span>
                          </Link>
                        </li>
                      )}
                      {perm.menuID.subMenuData.length > 0 ? (
                        <div
                          className="collapse"
                          id={perm._id}
                          data-bs-parent="#menu"
                        >
                          <ul className="side-nav-second-level">
                            {perm.menuID.subMenuData.map((subMenu) => {
                              return (
                                <li>
                                  <Link to={`/admin${subMenu.url}`}>
                                    {profile.companyData?.customProjectName
                                      ? getSubMenuName(
                                          subMenu.name,
                                          profile.companyData?.customProjectName
                                        )
                                      : subMenu.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}
                    </li>
                  );
                })
              )}
            </ul>
          ) : endPoint && endPoint === "employee" ? (
            <ul className="side-nav" id="employee">
              <li className="side-nav-item">
                <Link
                  to="/employee/dashboard"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i class="bi bi-grid-3x3-gap"></i>
                  <span> Dashboard </span>
                </Link>
              </li>

              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarProjects"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-box-seam"></i>
                  <span>
                    {profile?.companyData?.customProjectName
                      ? getSubMenuName(
                          "My Projects",
                          profile?.companyData?.customProjectName
                        )
                      : "My Projects"}
                  </span>
                  <span className="menu-arrow"></span>
                </Link>

                <div className="collapse px-2" id="sidebarProjects">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link to={`/employee/projects`}>
                        {profile?.companyData?.customProjectName
                          ? getSubMenuName(
                              "All Projects",
                              profile?.companyData?.customProjectName
                            )
                          : "All Projects"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/employee/projects?status=0">
                        {profile?.companyData?.customProjectName
                          ? getSubMenuName(
                              "Pending Projects",
                              profile?.companyData?.customProjectName
                            )
                          : "Pending Projects"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/employee/projects?status=3">
                        {profile?.companyData?.customProjectName
                          ? getSubMenuName(
                              "Completed Projects",
                              profile?.companyData?.customProjectName
                            )
                          : "Completed Projects"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="side-nav-item">
                <Link to="/employee/reports" className="side-nav-link">
                  <i class="bi bi-repeat"></i>
                  <span> Work Report </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarTickets"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-ticket-perforated"></i>
                  <span> Tickets </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarTickets">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link to={`/employee/create/ticket`}>Create Ticket</Link>
                    </li>
                    <li>
                      <Link to={`/employee/tickets`}>All Tickets</Link>
                    </li>
                    <li>
                      <Link to="/employee/tickets?status=0">New Tickets</Link>
                    </li>
                    <li>
                      <Link to="/employee/tickets?status=3">In Progress</Link>
                    </li>
                    <li>
                      <Link to="/employee/tickets?status=2">Under Review</Link>
                    </li>
                    <li>
                      <Link to="/employee/tickets?status=1">Completed</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#EmpAttendance"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-diagram-3"></i>
                  <span>HRMS </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="EmpAttendance">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link to={`/employee/Emp_Attendance`}>Attendance</Link>
                    </li>
                    <li>
                      <Link to={`/employee/EmpLeaves`}>Leaves</Link>
                    </li>
                    <li>
                      <Link to={`/employee/UploadDoc`}>Upload Document</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarLeads"
                  aria-expanded="false"
                  aria-controls="sidebarLeads"
                  className="side-nav-link"
                >
                  <i class="bi bi-node-plus"></i>
                  <span> Leads </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarLeads">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link to={`/employee/create/lead`}>Create Lead</Link>
                    </li>
                    <li>
                      <Link to={`/employee/leads`}>All Leads</Link>
                    </li>
                    <li>
                      <Link to={`/employee/leads?status=0`}>New Leads</Link>
                    </li>
                    <li>
                      <Link to={`/employee/leads?status=1`}>
                        Converted Leads
                      </Link>
                    </li>
                    <li>
                      <Link to={`/employee/leads?status=2`}>
                        Contacted Leads
                      </Link>
                    </li>
                    <li>
                      <Link to={`/employee/leads?status=3`}>Lost Leads</Link>
                    </li>
                    <li>
                      <Link to={`/employee/leads?status=4`}>Hold Leads</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="side-nav-item">
                <Link
                  to="/employee/to-dos"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i class="bi bi-card-checklist"></i>
                  <span> To Do </span>
                </Link>
              </li>

              <li className="side-nav-item">
                <Link
                  to="/employee/expense"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i class="bi bi-card-checklist"></i>
                  <span> Expense </span>
                </Link>
              </li>

              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarSupport"
                  aria-expanded="false"
                  aria-controls="sidebarSupport"
                  className="side-nav-link"
                >
                  <i class="bi bi-person-workspace"></i>
                  <span>Support </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarSupport">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link to={`/employee/support/query/add`}>Add Query</Link>
                    </li>
                    <li>
                      <Link to={`/employee/support/queries`}>Queries</Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          ) : endPoint && endPoint === "user" ? (
            <ul className="side-nav">
              <li className="side-nav-item">
                <NavLink to="/user/dashboard" className="side-nav-link">
                  <i class="bi bi-grid-3x3-gap"></i>
                  <span> Dashboard </span>
                </NavLink>
              </li>
              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarProjects"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-box-seam"></i>
                  <span>
                    {profile.companyData?.customProjectName
                      ? getSubMenuName(
                          "My Projects",
                          profile?.companyData?.customProjectName
                        )
                      : "My Projects"}
                  </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarProjects">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link to={`/user/projects`}>
                        {profile.companyData?.customProjectName
                          ? getSubMenuName(
                              "All Projects",
                              profile?.companyData?.customProjectName
                            )
                          : "All Projects"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/projects?status=0">Pending Projects</Link>
                    </li>
                    <li>
                      <Link to="/user/projects?status=3">
                        Completed Projects
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="side-nav-item">
                <Link
                  data-bs-toggle="collapse"
                  to="#sidebarTickets"
                  aria-expanded="false"
                  aria-controls="sidebarTasksSettings"
                  className="side-nav-link"
                >
                  <i class="bi bi-ticket-perforated"></i>
                  <span> Tickets </span>
                  <span className="menu-arrow"></span>
                </Link>
                <div className="collapse px-2" id="sidebarTickets">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link to="/user/new-ticket">Create New Ticket</Link>
                    </li>
                    <li>
                      <Link to={`/user/tickets`}>All Tickets</Link>
                    </li>
                    <li>
                      <Link to="/user/tickets?status=0">Pending Tickets</Link>
                    </li>
                    <li>
                      <Link to="/user/tickets?status=4">Review Tickets</Link>
                    </li>
                    <li>
                      <Link to="/user/tickets?status=3">Completed Tickets</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="side-nav-item">
                <Link
                  to="/user/appointments"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i class="bi bi-calendar3"></i>
                  <span> Appointments </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link className="side-nav-link" onClick={LogOut}>
                  <i class="bi bi-box-arrow-right"></i>
                  <span> Logout</span>
                </Link>
              </li>
            </ul>
          ) : endPoint && endPoint === "supadmin" ? (
            <ul className="side-nav mt-1">
              <li className="side-nav-item">
                <Link to="/supadmin/dashboard" className="side-nav-link">
                  <i class="bi bi-grid-3x3-gap"></i>
                  <span> Dashboard </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link to="/supadmin/admins" className="side-nav-link">
                  <i class="bi bi-card-checklist"></i>
                  <span> Admins </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link to="/supadmin/menu" className="side-nav-link">
                  <i class="bi bi-menu-button-fill"></i>
                  <span> Menu </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link to="/supadmin/enquiries" className="side-nav-link">
                  <i class="bi bi-person-lines-fill"></i>
                  <span> Enquiries </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link to="/supadmin/plan/taken/users" className="side-nav-link">
                  <i class="bi bi-person-check"></i>
                  <span> Subscribed Users </span>
                </Link>
              </li>
              <li className="side-nav-item">
                <Link to="/supadmin/support/queries" className="side-nav-link">
                  <i class="bi bi-person-workspace"></i>
                  <span> Support </span>
                </Link>
              </li>

              {/* <li className="side-nav-item">
                <Link to="/supadmin/sectors" className="side-nav-link">
                    <i class="bi bi-card-checklist"></i>
                    <span> Sectors </span>
                </Link>
              </li> */}
            </ul>
          ) : (
            <></>
          )}
          <div className="clearfix"></div>
        </div>


       



      </div>

      {endPoint && endPoint === "employee" ? (
      <div class="menubar-area style-2 footer-fixed">
			<div class="toolbar-inner menubar-nav">
				<Link to="/employee/projects" class="nav-link">
				<i class="bi bi-bar-chart"></i>
					<span>{profile?.companyData?.customProjectName ? ` ${profile?.companyData?.customProjectName}s` : " Projects"}</span>
				</Link>
				<Link to="/employee/tickets" class="nav-link">
        <i class="bi bi-ticket"></i>
					<span>Ticket</span>
				</Link>
				<Link to="/employee/dashboard" class="nav-link active">
					
          <i class="bi bi-house"></i>
					<span>Home</span>
				</Link>
				<Link to="/employee/Emp_Attendance" class="nav-link">
        <i class="bi bi-calendar-check"></i>
					<span>Punch In</span>
				</Link>
				<Link to="home-setting.html" class="nav-link" onClick={LogOut}>
        <i class="bi bi-power"></i>
					<span>Logout</span>
				</Link>
			</div>
		</div>
     ) : (
      <></>
    )}
    </>
  );
};
export default Header;
