import axios from "axios";
import { toast } from "react-toastify";

// Get URL based on the Environment (Local/Production)
export const getProdDevURL = () => {
    const prod = {
        REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL_PROD,
        REACT_APP_API_URL: process.env.REACT_APP_API_URL_PROD,
        REACT_APP_SERVER_PUBLIC_URL: process.env.REACT_APP_SERVER_PUBLIC_URL_PROD,
        REACT_APP_SOCKET_URL: process.env.REACT_APP_SOCKET_URL_PROD,
    }

    const dev = {
        REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL_DEV,
        REACT_APP_API_URL: process.env.REACT_APP_API_URL_DEV,
        REACT_APP_SERVER_PUBLIC_URL: process.env.REACT_APP_SERVER_PUBLIC_URL_DEV,
        REACT_APP_SOCKET_URL: process.env.REACT_APP_SOCKET_URL_DEV,

    }

    return process.env.NODE_ENV === "development" ? dev : prod;
};


// Date Time Options
export const dateTimeOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
}

// Date Options
export const dateOption = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    // hour: '2-digit', 
    // minute: '2-digit',  
    // hour12: false
}

// Create an Array from from a range.
export const arrayRange = (start, stop, step) => {
    let arr = Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step)

    return arr
}


// axios instance and interceptors
export const axiosInstance = axios.create({
    baseURL: getProdDevURL().REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("token");
        const loginUrlPattern = /\/login$/;   // Use a regular expression to match the URL

        if (!loginUrlPattern.test(config.url)) {
            config.headers["x-auth-token"] = token;   // Modify the request configuration here
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        // console.log(response);
        if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/"
        }
        return response;
    },
    function (error) {
        // console.log(error);
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/"
        }
        return Promise.reject(error);
    }
);


// Project status color getter (project schema)
export const getProjectStatusColor = (val) => {
    if (val === 0) {  // pending
        return "badge bg-warning"
    } else if (val === 1) {    // assigned
        return "badge bg-info"
    } else if (val === 2) {    // in projgress
        return "badge bg-primary"
    } else if (val === 3) {    // done
        return "badge bg-success"
    } else if (val === 4) {    // hold
        return "badge bg-danger"
    }
}

// Tickets status color getter (tickets schema)
export const getTicketStatusColor = (val) => {
    if (val === 0) {  // pending
        return "badge bg-danger"
    } else if (val === 1) {    // assigned
        return "badge bg-warning"
    } else if (val === 2) {    // in progress
        return "badge bg-success"
    } else if (val === 3) {    // complete
        return "badge bg-primary"
    } else if (val === 4) {    // review
        return "badge bg-dark"
    } else if (val === 5) {    // to discuss
        return "badge bg-info"
    } else if (val === 6) {    // Reopened
        return "badge bg-dark"
    }
};

// Activities status color getter (assignments schema)
export const getActivityStatusColor = (val) => {
    if (val === 0) {  // pending
        return "badge bg-danger"
    } else if (val === 1) {    // done
        return "badge bg-primary"
    } else if (val === 2) {    // review
        return "badge bg-dark"
    } else if (val === 3) {    // in progress
        return "badge bg-success"
    } else if (val === 4) {    // reopened
        return "badge bg-warning"
    } else if (val === 5) {    // to discuss
        return "badge bg-info"
    } else if (val === 6) {    // re-assigned
        return "badge bg-info"
    }
}

// Appointment status color getter (appointments schema)
export const getApointmentStatusColor = (val) => {
    if (val === 0) {  // pending
        return "badge bg-warning"
    } else if (val === 1) {    // approved
        return "badge bg-success"
    } else if (val === 2) {    // cancel
        return "badge bg-danger"
    } else if (val === 3) {    // expired
        return "badge bg-dark"
    } else if (val === 4) {    // rescheduled
        return "badge bg-primary"
    }
}

// Employee Uploaded Doc status color getter (tickets schema)
export const getEmpUploadedDocStatusColor = (val) => {
    if (val === 0) {  // pending
        return "bg-warning"
    } else if (val === 1) {    // Processing
        return "bg-warning"
    } else if (val === 2) {    // Approved
        return "badge bg-success"
    } else if (val === 3) {    // Rejected
        return "badge bg-danger"
    } 
};

// Employee Uploaded Doc dummy image getter (tickets schema)
export const getEmpUploadedDocImgType = (val) => {
    if (val === 0) {  // pending
        return "/files/pendingdoc.png"
    } else if (val === 1) {    // Processing
        return "/files/nonedoc.png"
    } else if (val === 2) {    // Approved
        return "/files/approvedoc.png"
    } else if (val === 3) {    // Rejected
        return "/files/rejectdoc.png"
    } 
};

// Employee Uploaded Doc dummy image getter (tickets schema)
export const getEmpUploadedDocStatusIconType = (val) => {
    if (val === 0) {  // pending
        return "bi bi-ban"
    } else if (val === 1) {    // Processing
        return "bi bi-ban"
    } else if (val === 2) {    // Approved
        return "bi bi-check2-circle"
    } else if (val === 3) {    // Rejected
        return "bi bi-ban"
    } 
};


export const fetchComponentPrefrences = (preferences, compName) => {

    let style = {}

    if (preferences && preferences.length > 0) {

        const preference = preferences.find((preference) => preference.componentName === compName);

        style.backgroundColor = preference.bgColor ?? "";
        style.color = preference.textColor ?? "";

    }

    return style


}

export const getMenuName = (menuName, customProjectName) => {
    if (menuName === "Projects") {
        return customProjectName
    }

    return menuName
}

export const getSubMenuName = (subMenuName, customProjectName) => {
    const arr = subMenuName.split(" ");
    // ["add", "projects"]

    if (arr[1] === "Project") {

        arr[1] = customProjectName;
        const word = arr.join(" ");
        return word

    } else if (arr[1] === "Projects") {

        arr[1] = `${customProjectName}s`;
        const word = arr.join(" ");
        return word
    }else if (arr[0] === "Project"){
        arr[0] = customProjectName;
        const word = arr.join(" ");
        return word
    }

    return subMenuName

}