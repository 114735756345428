import React from 'react';

const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const Admins = React.lazy(() => import('./Admins/admins'));
const ManageAdmin = React.lazy(() => import('./Admins/manage-admin'));
const Admin = React.lazy(() => import('./Admins/admin'));
const Menu = React.lazy(() => import('./Menu/menu'));
const SubMenu = React.lazy(() => import('./Menu/sub-menu'));
const ManageSubMenu = React.lazy(() => import('./Menu/manage-subMenu'));
const Services = React.lazy(()=> import('./Services/Services'));
const Queries = React.lazy(()=> import('./Support/Queries'));
const Query = React.lazy(() => import('./Support/Query'));
const Enquiries = React.lazy(() => import('./Enquiries/Enquiries'));



const Error404 = React.lazy(() => import('../Components/Error404'));

const SuperAdminRoutes = [
    {
        path: 'supadmin',
        children: [
            {
                path: 'dashboard',
                element: <Dashboard/>
            },
            {
                path:'admins',
                element: <Admins/>
            },
            {
                path:'admin/create',
                element: <ManageAdmin/>
            },
            {
                path:'admin/edit/:adminID',
                element: <ManageAdmin/>
            },
            {
                path:'admin/:adminID',
                element: <Admin/>
            },
            {
                path:'menu',
                element: <Menu/>
            },
            {
                path:'menu/:menuID',
                element: <SubMenu/>
            },
            {
                path:'sub-menu/create/:menuID',
                element: <ManageSubMenu/>
            },
            // {
            //     path:'services',
            //     element: <Services/>
            // },
            {
                path:'support/queries',
                element: <Queries/>
            },
            {
                path: 'support/query/:supportQueryID/tenant/:tenant',
                element: <Query/>
            },
            {
                path:'enquiries',
                element: <Enquiries/>
            },
            {
                path: '*',
                element: <Error404/>
            }
        ]
    }
]
export default SuperAdminRoutes;