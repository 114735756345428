import React from 'react';
import EmployeeAllownaceDetail from './Allowance/Allowance';

const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const Work_Report = React.lazy(() => import('./Report/Work_Report'));
const Change_Password = React.lazy(() => import('./Profile/Change_Password'));
const Salary_Slip = React.lazy(() => import('./Salary/Salary_Slip'));
const All_Project = React.lazy(() => import('./Project/All_Project'));
const Project = React.lazy(() => import('./Project/Project'));
const ProjectTickets = React.lazy(()=>import('./Project/ProjectTickets'));
const ProjectTicket = React.lazy(()=>import('./Project/ProjectTicket'));
const All_Ticket = React.lazy(() => import('./Ticket/All_Ticket'));
const ManageTicket = React.lazy(() => import('./Ticket/Manage_Ticket'));
const Profile = React.lazy(() => import('./Profile/Profile'));
const ManageProfile = React.lazy(() => import('./Profile/ManageProfile'));
const Messages = React.lazy(() => import('./chat/messages'));
const LogDetails = React.lazy(() => import('./Project/logDetails'));
const Activities = React.lazy(() => import('./Ticket/activities'));
const EmpAttendance = React.lazy(() => import('./Attendance/Attendance'));
const EmpLeaves = React.lazy(() => import('./Attendance/Leaves'));

const Leads = React.lazy(() => import('./Leads/Leads'));
const ManageLead = React.lazy(() => import('./Leads/ManageLead'));
const Lead = React.lazy(() => import('./Leads/Lead'));
const LeadLogs = React.lazy(() => import('./Leads/Log'));
const ToDos = React.lazy(() => import('./ToDo/ToDos'));
const UploadDoc = React.lazy(() => import('./Document/UploadDocument'));

const Queries = React.lazy(() => import('./Support/Queries'));
const ManageQuery = React.lazy(() => import('./Support/ManageQuery'));
const Query = React.lazy(() => import('./Support/Query'));

const Error404 = React.lazy(() => import('./../Components/Error404'));
const EmployeeRoutes = [
    {
        path: 'employee',
        children: [
            {
                path: 'dashboard',
                element: <Dashboard/>
            },
            {
                path: 'reports',
                element: <Work_Report/>
            },
           
            {
                path: 'profile',
                element: <Profile/>
            },
            {
                path: 'salary_slip',
                element: <Salary_Slip/>
            },
            {
                path: 'projects',
                element: <All_Project/>
            },
            {
                path: 'project/:projectID',
                element: <Project/>
            },
            {
                path:'log/:projectID/:logID',
                element:<LogDetails/>
            },
            {
                path: 'project/:projectID/tickets',
                element: <ProjectTickets/>
            },
            {
                path: 'project/:projectID/ticket/:ticketID',
                element: <ProjectTicket/>
            },
            {
                path: 'change_password',
                element: <Change_Password/>
            },

            {
                path: 'Emp_Attendance',
                element: <EmpAttendance/>
            },


            {
                path: 'EmpLeaves',
                element: <EmpLeaves/>
            },
            {
                path: 'tickets',
                element: <All_Ticket/>
            },
            {
                path: 'create/ticket',
                element: <ManageTicket/>
            },
            {
                path: 'activities',
                element: <Activities/>
            },
            {
                path: 'profile',
                element: <Profile/>
            },
            {
                path: 'profile/edit',
                element: <ManageProfile/>
            },
            {
                path:'messages',
                element: <Messages/>
            },
            {
                path:'create/lead',
                element: <ManageLead/>
            },
            {
                path:'leads',
                element: <Leads/>
            },
            {
                path:'lead/:leadID',
                element: <Lead/>
            },

            {
                path:'UploadDoc',
                element: <UploadDoc/>
            },
            {
                path:'to-dos',
                element: <ToDos/>
            },
            {
                path:'expense',
                element: <EmployeeAllownaceDetail/>
            },
            {
                path:'lead/logs/:leadID',
                element: <LeadLogs/>
            },
            {
                path: 'support/queries',
                element: <Queries/>
            },
            {
                path: 'support/query/add',
                element: <ManageQuery/>
            },
            {
                path: 'support/query/:supportQueryID',
                element: <Query/>
            },
            {
                path: '*',
                element: <Error404/>
            }
        ]
    }
]
export default EmployeeRoutes;