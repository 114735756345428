import React, { useEffect, useState } from "react";
import { axiosInstance, dateOption, getEmpUploadedDocImgType, getEmpUploadedDocStatusColor, getEmpUploadedDocStatusIconType, getProdDevURL } from "../../../helper";
import { toast } from "react-toastify";
import TablePlaceholder from "../../Placeholders.jsx/TablePlaceholder";
import Pagination from "react-js-pagination";

export default function EmployeeDoc() {
  const [isLoading, setIsLoading] = useState(true);
  const [documentTypes, setDocumentTypes] = useState([]); // stores the types of documents
  const [usersWithUploadedDocuments, setUsersWithUploadedDocuments] = useState([]); // stores the types of documents
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [docData, setDocData] = useState({});    // storesthe selected document data
  const [filter, setFilter] = useState({
    search: null,
  });

  // Fetch Types of Docs
  const fetchDocumentTypes = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get("admin/documents");

      console.log("document Types: ", res);

      if (res.data.status === "success") {
        setDocumentTypes(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Uploaded documents
  const fetchUploadedDocs = async (pageNumber) => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get("admin/uploaded/documents", {
        params: {
          filter: filter,
          page: pageNumber,
          limit: rowsPerPage,
        },
      });

      console.log("uploaded docs: ", res);

      if (res.data.status === "success") {
        setUsersWithUploadedDocuments(res.data.data);
        setPage(res.data.page);
        setTotal(res.data.total);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Document Status change
  const handleApproveReject = async(clickType)=> {

    // Force user to enter remark if option for rejection
    if(clickType === 3 && docData?.status?.remark?.length === 0){
      return toast.info("Please Enter a Remark for Rejection");
    }

    document.getElementById("modalClose").click();

   setIsLoading(true);

   try {

      const res = await axiosInstance.put("/admin/empdoc/status", {rejectRemark: docData?.status?.remark}, {
         params: {
            clickType: clickType,
            empDocID: docData._id
         }
      });

      if(res.data.status === "success"){
         toast.success(res.data.message);
         fetchUploadedDocs();
      }else if(res.data.status === "error"){
         toast.error(res.data.message)
      }
      
   } catch (error) {
      console.log(error);
   }finally{
      setIsLoading(false)
   }
  }

  const handleSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const mappedData = usersWithUploadedDocuments.map((userWithDocs) => {
    
    const documents = documentTypes.map((docType) => {
      
      const uploadedDoc = userWithDocs.uploadedDocs.find((uploaded) => uploaded.docID === docType._id);

      return {
        docType: docType.name,  // Document type name
        uploadedDoc,           // Corresponding uploaded document object (if found)
      };
    });

    return { ...userWithDocs, documents }; // Combine user data with mapped documents

  });

  // console.log(mappedData);

  useEffect(() => {

    if(usersWithUploadedDocuments.length === 0 ){

      fetchDocumentTypes();
      fetchUploadedDocs();

    }else{
      const timeoutID = setTimeout(() => {
        fetchDocumentTypes();
        fetchUploadedDocs();
      }, 1000);
  
      return () => clearTimeout(timeoutID);

    }
    

  }, [filter.search]);

  // useEffect(() => {
  //   fetchDocumentTypes();
  //   fetchUploadedDocs();
  // }, []);

  return (
    <>
      <div class="content">
        <div class="container-fluid">
          <div class="card-header d-sm-flex align-items-center  justify-content-between">
            <h4 class="card-title flex-fill">HRMS</h4>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between">
                  <div class="card-title d-flex gap-3">
                    <span>Employee Document</span>
                    <div class="d-flex gap-2"></div>
                  </div>
                  <div class="flex flex-wrap gap-2">
                    <span class="badge badge-md   bg-primary-transparent">
                      Today : {new Date(Date.now()).toLocaleString("en-IN", dateOption)}
                    </span>
                  </div>
                </div>
                <div class="card-body py-2 px-2">
                  <div class="row mb-2 filter-row">
                    <div class="col-sm-3">
                      <div class="input-block mb-1 form-focus focused">
                        <div class="cal-icon">
                          <input
                            name="search"
                            value={filter.search}
                            onChange={handleSearchChange}
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Employee Name / Code"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table text-nowrap table-hover border table-bordered">
                      <thead>
                        <tr>
                          <th>Employee ID</th>
                          <th>Employee Name</th>
                          {isLoading
                            ? null
                            : documentTypes.map((docType) => {
                                return (
                                  <th id={`doctype${docType._id}`} refID={`doctype${docType._id}`} key={docType._id} className="text-center">
                                    {docType.name}
                                  </th>
                                );
                              })}
                             
                        </tr>
                      </thead>
                      {isLoading ? (
                        <TablePlaceholder colNum={3} />
                      ) : (
                        // <tbody>  
                        //    {
                        //       isLoading ?
                        //       null
                        //       :
                        //       (
                        //          usersWithUploadedDocuments.map((userWithDocs)=> {
                        //             return(
                        //                <tr key={userWithDocs._id}>
                        //                   <td>{userWithDocs?.empCode ?? ""}</td>
                        //                   <td class="fw-500">
                        //                      <a href="/admin/attendancedetail/66b49f12e864e24d0612f52b">
                        //                      {userWithDocs?.firstName ?? ""} {userWithDocs?.lastName ?? ""}
                        //                      </a>
                        //                   </td>
                        //                   {
                        //                      userWithDocs?.uploadedDocs?.map((uploadedDoc)=> {

                        //                         const x = documentTypes?.map((docType)=> {
                        //                           console.log("matched", docType._id, "mmmm", uploadedDoc.docID);
                        //                            if(docType._id === uploadedDoc.docID){
                        //                               return(
                        //                                  <td onClick={()=> setDocData(uploadedDoc)} key={uploadedDoc._id} class="docimg text-center" data-bs-toggle="modal" data-bs-target="#empdocpop">
                        //                                     <img src={getEmpUploadedDocImgType(uploadedDoc?.status?.value)} alt="" />
                        //                                     <span class={`badge ${getEmpUploadedDocStatusColor(uploadedDoc?.status?.value)}-transparent`}>
                        //                                       {" "}
                        //                                       <i class={getEmpUploadedDocStatusIconType(uploadedDoc?.status?.value)}></i> {uploadedDoc?.status?.name ?? ""}
                        //                                     </span>{" "}
                        //                                     <span className="fw-bold">{uploadedDoc?.docType?.name}</span> 
                        //                                  </td>
                        //                               )
                        //                            }else{
                        //                             return "not matched"
                        //                            }
                        //                         })
                        //                         console.log(x);
                        //                         return x
                        //                      })
                        //                   }
                        //                </tr>
                        //             )
                        //          })
                        //       )
                        //    }
                        // </tbody>

                        <tbody>
                          {isLoading ? (
                            <TablePlaceholder colNum={documentTypes.length + 2} />
                          ) : (
                            mappedData?.map((mappedUser) => (
                              <tr key={mappedUser?._id}>
                                <td>{mappedUser?.empCode || ""}</td>
                                <td className="fw-500">
                                  <a href="/admin/attendancedetail/66b49f12e864e24d0612f52b">
                                    {mappedUser?.firstName || ""} {mappedUser?.lastName || ""}
                                  </a>
                                </td>
                                {mappedUser?.documents?.map((mappedDoc) => (
                                  <td onClick={()=> setDocData(mappedDoc?.uploadedDoc)} key={mappedDoc?.docType} className="docimg text-center" data-bs-toggle="modal" data-bs-target="#empdocpop">
                                    {mappedDoc?.uploadedDoc ? (
                                      <>
                                        <img src={getEmpUploadedDocImgType(mappedDoc?.uploadedDoc?.status?.value)} alt="" />
                                        <span className={`badge ${getEmpUploadedDocStatusColor(mappedDoc?.uploadedDoc?.status?.value)}-transparent`}>
                                          <i className={getEmpUploadedDocStatusIconType(mappedDoc?.uploadedDoc?.status?.value)}></i>{" "}
                                          {mappedDoc?.uploadedDoc?.status?.name}
                                        </span>
                                        {/* <span className="fw-bold">{mappedDoc.docType}</span> */}
                                      </>
                                    ) : (
                                      "-" 
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                {/* <div class="row mb-3">
                  <ul class="pagination justify-content-center small mb-0">
                    <li class="page-item disabled">
                      <a
                        class="page-link"
                        href="/"
                        aria-label="Go to first page"
                      >
                        First
                      </a>
                    </li>
                    <li class="page-item disabled">
                      <a
                        class="page-link"
                        href="/"
                        aria-label="Go to previous page"
                      >
                        ⟨
                      </a>
                    </li>
                    <li class="page-item disabled">
                      <a
                        class="page-link"
                        href="/"
                        aria-label="Go to next page"
                      >
                        ⟩
                      </a>
                    </li>
                    <li class="page-item disabled">
                      <a
                        class="page-link"
                        href="/"
                        aria-label="Go to last page"
                      >
                        Last
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="row mb-3">
                    <Pagination
                        activePage={page}
                        totalItemsCount={total}
                        itemsCountPerPage={rowsPerPage}
                        onChange={(pageNumber) => fetchUploadedDocs(pageNumber)}
                        innerClass="pagination justify-content-center small mb-0"
                        itemClass="page-item"
                        linkClass="page-link"
                        activeLinkClass="active"
                        firstPageText="First"
                        lastPageText="Last"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal blurmodal fade"
        id="empdocpop"
        tabindex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h1 class="modal-title text-white fs-5" id="exampleModalLabel">
                Documant Name -{docData?.docType?.name ?? ""}
              </h1>
              <button
                id="modalClose"
                type="button"
                class="btn-close bg-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="card-body py-0 px-0 input">
                  <div class="row">
                     {
                        docData?.docNum 
                        ?
                        <div class=" col-12 mb-2">
                           <label class="form-label">Document No</label>
                           <input
                              name="name"
                              placeholder="enter Remarks"
                              type="text"
                              class="form-control"
                              id="name"
                              required=""
                              value="8798769654544"
                           />
                        </div>
                        :
                        null
                     }
                    
                    <div class=" col-12 mb-2">
                      <img
                        src={`${getProdDevURL().REACT_APP_SERVER_PUBLIC_URL}/${docData?.docFileUri}`}
                        className="img-fluid"
                        alt=""
                        style={{width: "200px", height: "200px"}}
                      />
                    </div>

                    <div class=" col-12 mb-2">
                      <label class="form-label">Remark</label>
                      <input
                        onChange={(e)=> setDocData((prev)=> ({...prev, status: {...prev.status, remark: e.target.value}}))}
                        name="rejectRemark"
                        value={docData?.status?.remark}
                        placeholder={docData?.status?.value === 0 ? `Enter Remarks` : "" }
                        type="text"
                        class="form-control"
                        id="name"
                        readOnly={docData?.status?.value === 0 ? false : true}
                      />
                    </div>
                  </div>
                  {
                    docData?.status?.value === 0
                    ?
                    (
                      <div class="row mt-1">
                        <div class="col-12">
                          <button
                            class="btn btn-sm btn-success font-13 "
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={()=> handleApproveReject(2)}
                          >
                            <div class="d-flex gap-2">
                              <i class="bi bi-save"></i>
                              <span> Approve</span>
                            </div>
                          </button>
                          <button
                            class="btn btn-sm btn-danger ms-2 font-13"
                            // data-bs-dismiss="modal"
                            // aria-label="Close"
                            onClick={()=> handleApproveReject(3)}
                          >
                            <i class="bi bi-backspace"></i> <span>Reject</span>
                          </button>
                        </div>
                      </div>
                    )
                    :
                    null
                  }
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
