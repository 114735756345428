import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Integrations() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

<Helmet>
      <title>Taskflier Integrations for Effortless Collaboration | Explore Our Integration Options </title>
      <meta name="description" content="Simplify your workflow with Taskflier's diverse range of integrations, designed to enhance collaboration and productivity. Explore our integration options and streamline your tasks today." />
      </Helmet>
	
      {" "}
      <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
      <link rel="stylesheet" type="text/css" href="/main/css/responsive.css"></link>

      <div className="main-page-wrapper font-rubik">
        <Header />

        <div class="fancy-hero-five">
				<div class="bg-wrapper">
					<div class="container">
						<div class="text-center row">
							<div class="col-xl-9 m-auto">
								<h1 class="heading">Integrations</h1>
								<p class="sub-heading space-xs font-gordita mt-15">We are a creative company that focuses on establishing long-term relationships with customers.</p>
							</div>
						</div>
					</div>
				</div>
		</div>


        <div class="fancy-text-block-eleven pt-200 md-mt-100 bg-white">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-6 order-lg-last">
							<div class="text-wrapper pt-0">
								<div class="title-style-two mb-35 md-mb-20">
									<h2><span>Service we offer is  <img src="images/shape/line-shape-5.svg" alt=""/></span>
										specifically designed to meet your needs.
									</h2>
								</div> 
								<p>With Benefits from deski Pro,  Earn rewards &amp; Score discounts on purchases* Foryourself and your customers.</p>
							</div>
						</div>
						<div class="col-lg-6 order-lg-first">
							<div class="video-box">
								<img src="images/media/img_106.png" alt=""/>
								<a data-fancybox="" href="https://www.youtube.com/embed/aXFSJTjVjw0" class="fancybox video-button d-flex align-items-center justify-content-center"><img src="images/icon/170.svg" alt=""/></a>
							</div> 
						</div>
					</div>
				</div>

				<div class="mt-70">
					<div class="container">
						<div class="row">
							<div class="col-lg-4">
								<div class="block-style-thirtyThree pr-lg-5 mt-40">
									<div class="icon"><img src="images/icon/171.svg" alt=""/></div>
									<h3 class="title"><span>Daily Updates</span></h3>
									<p>Elit esse cillum dolore eu fugiat nulla pariatur lorem elit</p>
								</div> 
							</div>
							<div class="col-lg-4">
								<div class="block-style-thirtyThree pr-lg-5 mt-40">
									<div class="icon"><img src="images/icon/172.svg" alt=""/></div>
									<h3 class="title"><span>Easu Cutomization</span></h3>
									<p>Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta.</p>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="block-style-thirtyThree pl-lg-5 mt-40">
									<div class="icon"><img src="images/icon/173.svg" alt=""/></div>
									<h3 class="title"><span>Fast Support</span></h3>
									<p>Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


        <div className="footer-bg-wrapper bg-white">
          <div className="bubble-one" />
          <div className="bubble-two" />
          <div className="bubble-three" />
          <div className="fancy-short-banner-two">
            <div className="container">
              <div className="content-wrapper">
                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                  <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                  <Link
                    to="https://mnbproject.com/#"
                    data-toggle="modal"
                    data-target="#contactModal"
                  >
                    Request Demo
                  </Link>
                  <div className="bubble-one" />
                  <div className="bubble-two" />
                  <div className="bubble-three" />
                  <div className="bubble-four" />
                  <div className="bubble-five" />
                </div>
             
              </div>
             
            </div>
           
          </div>
       
          <Footer />
        
        </div>



       



        <button className="scroll-top" style={{ display: "none" }}>
          <i className="fa fa-angle-up" aria-hidden="true" />
        
        </button>




      </div>
    </>
  );
}

export default Integrations;