import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function CookiePolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
      <title>Cookie Policy | Taskflier </title>
      <meta name="description" content="discription" />
      </Helmet>
      {" "}
      <link rel="stylesheet" type="text/css" href="/main/css/style.css"></link>
      <link rel="stylesheet" type="text/css" href="/main/css/responsive.css"></link>

      <div className="main-page-wrapper font-rubik">
        <Header />

        <div class="fancy-hero-five">
				<div class="bg-wrapper">
					<div class="container">
						<div class="text-center row">
							<div class="col-xl-9 m-auto">
								<h1 class="heading">Cookie Policy</h1>
								<p class="sub-heading space-xs font-gordita mt-15">We are a creative company that focuses on establishing long-term relationships with customers.</p>
							</div>
						</div>
					</div>
				</div>
		</div>


		<div class="fancy-feature-sixteen pt-80 md-mt-100 bgwhite  " id="feature">
   <div class="container">
      <div class="title-style-twelve">
         <div class="row align-items-center">
            <div class="col-lg-12 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
               <div class="text-wrapper">

               <h3 class="maintitle3 mt-0">Cookies</h3> 
               <p className="mainp3">Welcome to Taskflier! We use cookies to enhance your experience on our website and app. Here’s a detailed look at what cookies are, how we use them, and what choices you have.</p>

 <h3 class="maintitle3">What Are Cookies?</h3>
 <p className="mainp3">Cookies are small text files placed on your device (computer, smartphone, tablet) when you visit our website or use our app. They help us remember your preferences, improve functionality, and provide a better user experience.</p>

<h3 class="maintitle3">How We Use Cookies</h3>
<h3 class="maintitle3">Essential Cookies</h3>

<p className="mainp3"><b>Purpose</b>: These cookies are crucial for Taskflier to operate smoothly. They enable basic functions such as logging in, navigating through pages, and using essential features.</p>
<p className="mainp3"><b>Examples</b>: Keeping you logged in during your session, remembering your shopping cart items.</p>

<h3 class="maintitle3">Performance Cookies</h3>

<p className="mainp3"><b>Purpose</b>: These cookies help us understand how you use Taskflier, allowing us to improve our services. They collect information about how often you visit our site, which pages you view, and if you encounter any errors.</p>
<p className="mainp3"><b>Examples</b>: Tracking page views and user interactions to improve website performance.</p>

<h3 class="maintitle3 ">Functionality Cookies</h3>

<p className="mainp3"><b>Purpose</b>: These cookies remember your settings and preferences, so you don’t have to re-enter them each time you visit.</p>
<p className="mainp3"><b>Examples</b>: Saving your language choice or preferred layout.</p>

<h3 class="maintitle3 ">Marketing Cookies</h3>

<p className="mainp3"><b>Purpose</b>: We use these cookies to show you relevant ads and measure the success of our marketing efforts. They help us display advertisements that match your interests based on your browsing habits.</p>
<p className="mainp3"><b>Examples</b>: Showing ads related to Taskflier’s features and services.</p>

<h3 class="maintitle3">Third-Party Cookies</h3>
<p className="mainp3">We may work with trusted third-party partners who also use cookies to help us understand how you use Taskflier and to deliver relevant advertisements. These third parties have their own privacy policies and cookie practices.</p>

<h3 class="maintitle3">Managing Cookies</h3>
<p className="mainp3">You have control over cookies! You can manage or block cookies through your browser settings. Here’s how you can do it:</p>

<p className="mainp3"><b>Browser Settings</b>: Most browsers allow you to manage cookie preferences, such as blocking or deleting cookies. Check your browser’s help section for details.</p>
<p className="mainp3"><b>Opting Out</b>: You can opt out of certain cookies, especially marketing ones, using tools provided by third-party advertisers. For example, you can use the Network Advertising Initiative or the Digital Advertising Alliance tools.</p>

<h3 class="maintitle3">Impact of Disabling Cookies</h3>
<p className="mainp3">If you choose to disable cookies, you may experience reduced functionality on Taskflier. Some features may not work as expected, and you might need to re-enter your preferences more frequently.</p>

<h3 class="maintitle3 ">Updates to This Policy</h3>
<p className="mainp3">We may update this cookie policy occasionally to reflect changes in technology or legal requirements. We’ll post any changes on this page, so please review it regularly to stay informed.</p>


                
			  		  
				</div>
               
            </div>
           
			
         </div>
      </div>
     
    

</div>
</div>



       
            <div className="footer-bg-wrapper bg-white">
          <div className="bubble-one" />
          <div className="bubble-two" />
          <div className="bubble-three" />
          <div className="fancy-short-banner-two">
            <div className="container">
              <div className="content-wrapper">
                <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                  <h2>Still Having a Doubt? Clear Them With a Free Demo.</h2>
                  <Link
                    to="https://mnbproject.com/#" data-toggle="modal" data-target="#contactModal" >
                    Request Demo
                  </Link>
                  <div className="bubble-one" />
                  <div className="bubble-two" />
                  <div className="bubble-three" />
                  <div className="bubble-four" />
                  <div className="bubble-five" />
                </div>
             
              </div>
             
            </div>
           
          </div>
       
          <Footer />
        
        </div>



        <button className="scroll-top" style={{ display: "none" }}>
          <i className="fa fa-angle-up" aria-hidden="true" />
        
        </button>




      </div>
    </>
  );
}

export default CookiePolicy;