import React from "react";
import PricingPage from "./HomePage/Pricing";
import PaymentSuccess from "./HomePage/PaymentSuccess";
import Contactus from "./HomePage/Contactus";
import Privacy from "./HomePage/PrivacyPolicy";
import CookiePolicy from "./HomePage/CookiePolicy";
import Security from "./HomePage/Security";
import TermsofService from "./HomePage/TermsofService";
import Solutions from "./HomePage/Solutions";
import Integrations from "./HomePage/Integrations";
import Customers from "./HomePage/Customers";
import Aboutus from "./HomePage/about";
import Features from "./HomePage/Features";
import Capabilities from "./HomePage/Capabilities";
import Demosuccess from "./HomePage/Enquerysuccess";


const HomePage = React.lazy(() => import("./HomePage/HomePage"));
const Login = React.lazy(() => import("./Login"));
const ResetPasswrod = React.lazy(() => import("./ResetPasswrod"));
const PlanDetails = React.lazy(() => import("./HomePage/PlanDetails"));
const PaymentDetails = React.lazy(() => import("./HomePage/PaymentSuccess"));
const PaymentResponse = React.lazy(() => import("./HomePage/PaymentResponse"));

const Public = [
    {
        path: "/",
        element: <HomePage />,
    },
    
    {
        path: "/enquiry-raised",
        element: <Demosuccess />,
    },

    {
        path: "/pricing",
        element: <PricingPage />,
    },

    {
        path: "/Solutions",
        element: <Solutions />,
    },

    {
        path: "/Integrations",
        element: <Integrations />,
    },

    {
        path: "/Customers",
        element: <Customers />,
    },
    {
        path: "/Aboutus",
        element: <Aboutus />,
    },

    {
        path: "/Features",
        element: <Features />,
    },

    {
        path: "/Capabilities",
        element: <Capabilities />,
    },


    {
        path: "/Paymentsuccess",
        element: <PaymentSuccess />,
    },


    {
        path: "/Demosuccess",
        element: <Demosuccess />,
    },

    {
        path: "/contact",
        element: <Contactus />,
    },


    {
        path: "/CookiePolicy",
        element: <CookiePolicy />,
    },

    {
        path: "/Privacy",
        element: <Privacy />,
    },

    {
        path: "/Security",
        element: <Security />,
    },

    {
        path: "/Terms_of_Service",
        element: <TermsofService />,
    },


  

    {
        path: "/plan/:type/:id",
        element: <PlanDetails />,
    },
    {
        path: "/transaction/:id",
        element: <PaymentDetails />,
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "recoverpassword",
        element: <ResetPasswrod />,
    },
];

// const Public = [
//   {
//     path: "/",
//     children: [
//       {
//         path: "home",
//         element: <HomePage />,
//       },
//       {
//         path: "login",
//         element: <Login />,
//       },
//       {
//         path: "recoverpassword",
//         element: <ResetPasswrod />,
//       },
//     ],
//   },
// ];

export default Public;
